import React, { useEffect, useState } from 'react';
import { useSearch } from './SearchContext';
import { formatPrice, getCookie, setCategoryCookie } from '../../services/function';
import { apiFunctions } from '../../services/api.services';

const ResultsComponent = () => {
  const { results, loading, query } = useSearch();
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  useEffect(()=>{
    apiFunctions.category((data) => {
      setCategory(data)
    });
  }, [categoryId, apiFunctions])
  useEffect(() => {
    const checkCookieValue = () => {
      const cookieValue = parseInt(getCookie('category_id'));
      setCategoryId(isNaN(cookieValue) ? null : cookieValue);
    };
    checkCookieValue();
    const intervalId = setInterval(checkCookieValue, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(()=>{
    const cookieValue = parseInt(getCookie('category_id'));
    // console.log(cookieValue,'cookies')
    setCategoryId(isNaN(cookieValue) ? null : cookieValue);
  }, [])

  return (
    <>
    <main class="main">
        <div class="page-header mt-30 mb-50">
            <div class="container">
                <div class="archive-header">
                    <div class="row align-items-center">
                        <div class="col-xl-12">
                            <h1 class="mb-15">Hasil Pencarian “{query}”</h1>
                            <div class="breadcrumb">
                                <a href="index.html" rel="nofollow"><i class="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Pencarian
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {loading ? (
        <p>Mohon tunggu, produk akan muncul sebentar lagi...</p>
        ) : results.length > 0 ? (
        <div class="container mb-30">
            <div class="row">
                <div class="col-12">
                    <div class="shop-product-fillter">
                        <div class="totall-product">
                            <p>Kami menemukan <strong class="text-brand">{results.length}</strong> produk terkait!</p>
                        </div>
                        {/* <div class="sort-by-product-area">
                            <div class="sort-by-cover mr-10">
                                <div class="sort-by-product-wrap">
                                    <div class="sort-by">
                                        <span>Show:<i class="fi-rs-apps"></i></span>
                                    </div>
                                    <div class="sort-by-dropdown-wrap">
                                        <span> 50 <i class="fi-rs-angle-small-down"></i></span>
                                    </div>
                                </div>
                                <div class="sort-by-dropdown">
                                    <ul>
                                        <li><a class="active" href="#">50</a></li>
                                        <li><a href="#">100</a></li>
                                        <li><a href="#">150</a></li>
                                        <li><a href="#">200</a></li>
                                        <li><a href="#">All</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="sort-by-cover">
                                <div class="sort-by-product-wrap">
                                    <div class="sort-by">
                                        <span>Sort by:<i class="fi-rs-apps-sort"></i></span>
                                    </div>
                                    <div class="sort-by-dropdown-wrap">
                                        <span> <i class="fi-rs-angle-small-down"></i> Featured</span>
                                    </div>
                                </div>
                                <div class="sort-by-dropdown">
                                    <ul>
                                        <li><a class="active" href="#">Featured</a></li>
                                        <li><a href="#">Price: Low to High</a></li>
                                        <li><a href="#">Price: High to Low</a></li>
                                        <li><a href="#">Release Date</a></li>
                                        <li><a href="#">Avg. Rating</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div class="row product-grid">
                        {results.map((product, index) => (
                        <div class="col-lg-1-5 col-md-4 col-12 col-sm-6">
                            <div class="product-cart-wrap mb-30">
                                <div class="product-img-action-wrap">
                                    <div class="product-img product-img-zoom">
                                        <a href={`/product/${product.item_group_id}`}>
                                            <img class="default-img" src={product.thumbnail} alt="" />
                                            {/* <img class="hover-img" src="assets/imgs/shop/product-5-2.jpg" alt="" /> */}
                                        </a>
                                    </div>
                                    
                                    {/* <div class="product-badges product-badges-position product-badges-mrg">
                                        <span class="hot">Hot</span>
                                    </div> */}
                                </div>
                                <div class="product-content-wrap">
                                    <div class="product-category">
                                        {/* <a href="shop-grid-right.html">Keke</a> */}
                                    </div>
                                    <h2><a href={`/product/${product.item_group_id}`}>{product.item_name}</a></h2>
                                    <div>
                                        <span class="font-small text-muted"><a href="" onClick={() => setCategoryCookie(product.item_category_id)}>{category.filter(item => item.category_id === product.item_category_id).map(item=>item.category_name)}</a></span>
                                    </div>
                                    <div class="product-card-bottom">
                                        <div class="product-price">
                                            <span>{formatPrice(product.sell_price)}</span>
                                            {/* <span class="old-price">{formatPrice(product.sell_price+50000)}</span> */}
                                        </div>
                                        <div class="add-cart">
                                            <a class="add" href={`/product/${product.item_group_id}`}><i class="fi-rs-shopping-cart mr-5"></i>Add </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                    {/* <div class="pagination-area mt-20 mb-20">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-start">
                                <li class="page-item">
                                    <a class="page-link" href="#"><i class="fi-rs-arrow-small-right"></i></a>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                <li class="page-item active"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item"><a class="page-link dot" href="#">...</a></li>
                                <li class="page-item"><a class="page-link" href="#">6</a></li>
                                <li class="page-item">
                                    <a class="page-link" href="#"><i class="fi-rs-arrow-small-left"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div> */}
                </div>
            </div>
        </div>
        ) : (
            <p>Tidak ada hasil yang ditemukan.</p>
        )}
    </main>
    </>
  );
};

export default ResultsComponent;
