import { useEffect, useState, useRef } from "react";
import { formatPrice } from "../../services/function";
import AlertComponent, { useAlert } from "../../components/commons/alert";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import {
  addEthicaProductToCart,
  getProductsByArtikel,
} from "../../services/api.services";
import { addToCartEthica } from "../Cart/Cart";

export const EthicaProducts = () => {
  const { nama_artikel } = useParams();
  const [alert, showAlert] = useAlert();

  const [product, setProduct] = useState([]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [stock, setStock] = useState("0");
  const [stockSizes, setStockSizes] = useState("0");
  const [selectedImages, setSelectedImages] = useState({});
  const imageSliderRef = useRef(null);
  const thumbnailsRef = useRef(null);

  useEffect(() => {
    const fetchProduct = async () => {
      if (!nama_artikel) {
        console.error("Parameter nama_artikel tidak ditemukan.");
        return;
      }
      const data = await getProductsByArtikel(nama_artikel);
      setProduct(data);
      if (
        data &&
        Array.isArray(data) &&
        data.length > 0 &&
        data[0].list_warna?.length > 0
      ) {
        const initialColor = data[0].list_warna[0];
        setProduct(data[0]);
        setSelectedColor(initialColor.warna);
        setAvailableSizes(initialColor.list_ukuran);
        setStock(initialColor.stok);
        // setStockSizes(initialColor.list_ukuran[0].stok);
        // if (initialColor.list_ukuran.length > 0) {
        //   setSelectedSize(initialColor.list_ukuran[0].ukuran);
        // }
      } else {
        console.error(
          "Data produk tidak ditemukan atau tidak memiliki list_warna."
        );
      }
    };

    fetchProduct();
  }, [nama_artikel]);

  useEffect(() => {
    if (selectedSize && product?.list_warna) {
      const colorVariant = product.list_warna.find(
        (variant) => variant.warna === selectedColor
      );
      if (colorVariant) {
        const sizeVariant = colorVariant.list_ukuran.find(
          (size) => size.ukuran === selectedSize
        );
        if (sizeVariant) {
          setStockSizes(sizeVariant.stok);
        }
      }
    }

    if (selectedColor && product?.list_warna) {
      const colorVariant = product.list_warna.find(
        (variant) => variant.warna === selectedColor
      );
      if (colorVariant) {
        setStock(colorVariant.stok);
      }
    }
  }, [selectedSize, selectedColor, product]);

  const handleVariantClick = (variant) => {
    setSelectedColor(variant.warna);
    setSelectedSize(null);
    setAvailableSizes(variant.list_ukuran);
    setStock(variant.stok);
    if (variant.list_ukuran.length > 0) {
      // Pilih ukuran pertama secara default dan atur stok berdasarkan ukuran ini
      const firstSize = variant.list_ukuran.map((size) => ({
        ...size,
        stok: size.stok,
      }));
      setStockSizes(firstSize.stok);
    } else {
      setStockSizes(variant.stok); // Jika tidak ada ukuran, gunakan stok warna
    }
  };

  const getSelectedSizePrice = () => {
    if (selectedColor && selectedSize) {
      const colorVariant = product.list_warna.find(
        (variant) => variant.warna === selectedColor
      );
      if (colorVariant) {
        const sizeVariant = colorVariant.list_ukuran.find(
          (size) => size.ukuran === selectedSize
        );
        if (sizeVariant) {
          return sizeVariant.harga;
        }
      }
    }
    return product.harga; // Harga default jika tidak ada ukuran yang dipilih
  };

  const getSelectedSizeImages = () => {
    // Check if both color and size are selected
    if (selectedColor && selectedSize) {
      const colorVariant = product.list_warna.find(
        (variant) => variant.warna === selectedColor
      );
      if (colorVariant) {
        const sizeVariant = colorVariant.list_ukuran.find(
          (size) => size.ukuran === selectedSize
        );
        if (sizeVariant) {
          return {
            gambar: sizeVariant.gambar || product.gambar.normal,
            gambar_besar: sizeVariant.gambar_besar || product.gambar.besar,
            gambar_sedang: sizeVariant.gambar_sedang || product.gambar.sedang,
          };
        }
      }
    }
    // Fallback to default images if no size or color is selected or if properties are missing
    return {
      gambar: product.gambar?.normal || "default_normal_image_url",
      gambar_besar: product.gambar?.besar || "default_besar_image_url",
      gambar_sedang: product.gambar?.sedang || "default_sedang_image_url",
    };
  };

  useEffect(() => {
    const images = getSelectedSizeImages();
    setSelectedImages(images);
  }, [selectedColor, selectedSize]);

  const [isExpanded, setIsExpanded] = useState(false);
  const description =
    product?.list_warna?.[0]?.list_ukuran?.[0]?.keterangan ||
    "Tidak ada keterangan"; // Ensure description is defined
  const shortDescription = description.slice(0, 100);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (increment) => {
    if (!selectedSize) {
      showAlert("Anda harus memilih ukuran terlebih dahulu.", "warning");
      return;
    }
    const limit = stockSizes;
    const newQuantity = quantity + increment;
    if (newQuantity >= 1 && newQuantity <= limit) {
      setQuantity(newQuantity);
    } else if (newQuantity > limit) {
      showAlert("Anda telah mencapai stok maksimum.", "danger");
    } else if (newQuantity < 1) {
      setQuantity(1); // Set to minimum stock
    }
  };

  const handleQuantityInputChange = (e) => {
    if (!selectedSize) {
      showAlert("Anda harus memilih ukuran terlebih dahulu.", "warning");
      return;
    }
    const value = Number(e.target.value);
    const limit = stockSizes;
    if (value >= 1 && value <= limit) {
      setQuantity(value);
    } else if (value > limit) {
      showAlert("Anda telah mencapai stok maksimum.", "danger");
      setQuantity(limit); // Set to max stock
    } else if (value < 1) {
      setQuantity(1); // Set to minimum stock
    }
  };

  const handleAddToCart = async () => {
    if (!selectedSize || !selectedColor) {
      showAlert(
        "Anda harus memilih ukuran dan warna terlebih dahulu.",
        "warning"
      );
      return;
    }

    const selectedVariant = product.list_warna.find(
      (variant) => variant.warna === selectedColor
    );

    const sizeVariant = selectedVariant.list_ukuran.find(
      (size) => size.ukuran === selectedSize
    );

    if (!sizeVariant) {
      showAlert("Ukuran yang dipilih tidak valid.", "danger");
      return;
    }

    if (quantity < 1) {
      showAlert("Jumlah barang tidak valid.", "danger");
      return;
    }

    const seq = sizeVariant.seq;
    const cart = JSON.parse(localStorage.getItem("cart-ethica")) || [];
    const existingItemIndex = cart.findIndex((item) => item.seq === seq);

    if (existingItemIndex !== -1) {
      const newQuantity = cart[existingItemIndex].quantity + quantity;
      if (newQuantity <= product.stok) {
        cart[existingItemIndex].quantity = newQuantity;
      } else {
        showAlert("Anda telah mencapai stok maksimum.", "danger");
        return;
      }
    } else {
      if (quantity <= product.stok) {
        cart.push({
          ...product,
          seq,
          quantity,
          item_group: selectedVariant.item_group,
          item_name: product.nama,
        });
      } else {
        showAlert("Anda telah mencapai stok maksimum.", "danger");
        return;
      }
    }

    localStorage.setItem("cart-ethica", JSON.stringify(cart));
    showAlert("Produk ditambahkan ke keranjang.", "success");

    const event = new Event("cartUpdated");
    window.dispatchEvent(event);

    const userInfo = JSON.parse(localStorage.getItem("user-info")); // Asumsi userInfo disimpan di localStorage

    const payload = {
      barang_seq: seq,
      qty: quantity,
      user_id: userInfo.user.id, // Pastikan userInfo diimpor dan diatur dengan benar
    };

    try {
      const response = await addEthicaProductToCart(payload);
      if (response) {
        localStorage.setItem("cart-ethica", JSON.stringify(cart));
        showAlert(
          "Produk berhasil ditambahkan ke keranjang eksternal.",
          "success"
        );
      } else {
        showAlert("Gagal menambahkan produk ke keranjang eksternal.", "danger");
      }
    } catch (error) {
      console.error("Error:", error);
      showAlert(
        "Terjadi kesalahan saat menambahkan produk ke keranjang eksternal.",
        "danger"
      );
    }
  };

  return (
    <section>
      <AlertComponent alert={alert} />
      <main class="main">
        <div class="page-header breadcrumb-wrap">
          <div class="container">
            <div class="breadcrumb">
              <a href="/" rel="nofollow">
                <i class="fi-rs-home mr-5"></i>Beranda
              </a>
              <span></span> <a href="/product-ethica">Ethica</a> <span></span>{" "}
              {product.artikel}
            </div>
          </div>
        </div>
        <div class="container mb-75">
          <div class="row">
            <div class="col-xl-10 col-lg-12 m-auto">
              <div class="product-detail accordion-detail">
                {product.length !== 0 ? (
                  <div class="row mb-50 mt-30">
                    <div class="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                      {selectedImages && (
                        <div className="detail-gallery">
                          <span className="zoom-icon">
                            <i className="fi-rs-search"></i>
                          </span>

                          <div
                            className="product-image-slider"
                            ref={imageSliderRef}
                          >
                            {selectedImages.gambar_besar && (
                              <figure className="border-radius-10">
                                <img
                                  src={selectedImages.gambar_besar}
                                  alt="product image"
                                />
                              </figure>
                            )}
                          </div>

                          <div
                            className="slider-nav-thumbnails"
                            ref={thumbnailsRef}
                          >
                            {selectedImages.gambar && (
                              <div>
                                <img
                                  src={selectedImages.gambar.normal}
                                  alt="product image"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {/* <!-- End Gallery --> */}
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                      <div class="detail-info pr-30 pl-30">
                        <h2 class="title-detail">{product.artikel}</h2>

                        <div class="clearfix product-price-cover">
                          <div class="product-price primary-color float-left">
                            <span class="current-price text-brand">
                              {formatPrice(getSelectedSizePrice())}
                            </span>
                            <span>
                              {/* {userInfo && (
                                <>
                                  <span className="save-price font-md color3 ml-15">
                                    {userInfo
                                      ? `${Math.max(
                                          userInfo.user.role.discount,
                                          parseFloat(
                                            userInfo.user.special_discount
                                          )
                                        )}% Off`
                                      : "0% Off"}
                                  </span>
                                  <span className="old-price font-md ml-15">
                                    {selectedVar
                                      ? formatPrice(selectedVar.sell_price)
                                      : formatPrice(0)}
                                  </span>
                                </>
                              )} */}
                            </span>
                          </div>
                        </div>
                        <div class="short-desc mb-30">
                          <p class="font-lg">
                            {isExpanded ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: description,
                                }}
                              />
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: shortDescription,
                                }}
                              />
                            )}
                          </p>
                          {shortDescription && (
                            <button
                              onClick={toggleReadMore}
                              className="rounded mb-2 btn btn-sm position-relative"
                            >
                              {isExpanded
                                ? "Tampilkan sedikit"
                                : "Tampilkan lebih"}
                            </button>
                          )}
                        </div>
                        <div class="attr-detail attr-size mb-30">
                          <strong class="mr-10 mb-auto">Warna: </strong>
                          <ul class="list-filter size-filter font-small">
                            {product.list_warna &&
                            product.list_warna.length > 0 ? (
                              product.list_warna.map((variant) => {
                                const isDisabled =
                                  parseInt(variant.stok, 10) <= 0;
                                return (
                                  <li
                                    key={variant.warna}
                                    className={`${
                                      selectedColor === variant.warna
                                        ? "active"
                                        : ""
                                    } ${isDisabled ? "disabled" : ""}`}
                                  >
                                    <a
                                      onClick={
                                        !isDisabled
                                          ? () => handleVariantClick(variant)
                                          : null
                                      }
                                      className="d-flex justify-content-between"
                                    >
                                      <span>{variant.warna}</span>
                                    </a>
                                  </li>
                                );
                              })
                            ) : (
                              <p>Warna tidak tersedia</p>
                            )}
                          </ul>
                        </div>
                        <div class="attr-detail attr-size mb-30">
                          <strong class="mr-10 mb-auto ">Ukuran: </strong>
                          <ul class="list-filter size-filter font-small">
                            {availableSizes.map((size) => {
                              const isDisabled = parseInt(size.stok, 10) <= 0;
                              return (
                                <li
                                  key={size.ukuran}
                                  className={`${
                                    selectedSize === size.ukuran ? "active" : ""
                                  } ${isDisabled ? "disabled" : ""}`}
                                >
                                  <a
                                    onClick={
                                      !isDisabled
                                        ? () => setSelectedSize(size.ukuran)
                                        : null
                                    }
                                    className="d-flex justify-content-between gap-1"
                                  >
                                    <img
                                      src={size.gambar.normal}
                                      alt={size.ukuran}
                                      height="30px"
                                      className="m-auto mr-2"
                                    />{" "}
                                    -<span className="ml-2">{size.ukuran}</span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div class="font-xs">
                          <ul class="mr-50 float-start">
                            <li class="mb-5">
                              Stock:
                              <span class="in-stock text-brand ml-5">
                                {selectedSize ? stockSizes : stock} Item
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div className="detail-extralink mt-100 add-cart-lg">
                          <div className="detail-qty border radius">
                            <a
                              className={`qty-down ${
                                !selectedSize ? "disabled" : ""
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleQuantityChange(-1);
                              }}
                            >
                              <i className="fi-rs-angle-small-down"></i>
                            </a>
                            <input
                              type="text"
                              name="quantity"
                              className="qty-val"
                              value={quantity}
                              onChange={handleQuantityInputChange}
                              min="1"
                              disabled={!selectedSize}
                            />
                            <a
                              className={`qty-up ${
                                !selectedSize ? "disabled" : ""
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleQuantityChange(1);
                              }}
                            >
                              <i className="fi-rs-angle-small-up"></i>
                            </a>
                          </div>
                          <div className="product-extra-link2">
                            <button
                              type="button"
                              className="button button-add-to-cart"
                              onClick={handleAddToCart}
                            >
                              <i className="fi-rs-shopping-cart"></i>Tambah ke
                              keranjang
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <!-- Detail Info --> */}
                    </div>
                  </div>
                ) : (
                  <div className="text-center pt-50 pb-50">
                    <h3>Mohon tunggu, produk akan muncul sebentar lagi...</h3>
                  </div>
                )}

                {/* rating & testimoni */}
                {/* <div class="row mt-100">
                  <div class="col-12">
                    <h2 class="section-title style-1 mb-10">
                      Testimoni & Rating
                    </h2>
                  </div>
                  <div class="d-flex flex-column gap-3">
                    <RatingAverage />
                    <RatingFilter
                      selectedRating={selectedRating}
                      onFilterChange={setSelectedRating}
                    />
                    <RatingList ratingData={ratingData} />
                  </div>
                </div> */}

                {/* related products */}
                {/* <div class="row mt-100">
                  <div class="col-12">
                    <h2 class="section-title style-1 mb-30">
                      Related products
                    </h2>
                  </div>
                  <div class="col-12">
                    {RelatedVal.length > 0 ? (
                      <ProductsComponent
                        RelatedVal={RelatedVal}
                        categoryName={categoryName}
                      />
                    ) : (
                      <div className="text-center pt-50 pb-50">
                        <h3>
                          Mohon tunggu, produk akan muncul sebentar lagi...
                        </h3>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* <div class="detail-extralink add-cart-mobile">
          <div class="detail-qty border radius">
            <a
              onClick={(e) => {
                e.preventDefault();
                handleQuantityChange(-1);
              }}
              class="qty-down"
            >
              <i class="fi-rs-angle-small-down"></i>
            </a>
            <input
              type="text"
              name="quantity"
              class="qty-val"
              value={quantity}
              onChange={handleQuantityInputChange}
              min="1"
            />
            <a
              onClick={(e) => {
                e.preventDefault();
                handleQuantityChange(1);
              }}
              class="qty-up"
            >
              <i class="fi-rs-angle-small-up"></i>
            </a>
          </div>
          <div class="product-extra-link2">
            <button
              type="submit"
              class="button button-add-to-cart"
              onClick={handleAddToCart}
            >
              <i class="fi-rs-shopping-cart"></i>Add to cart
            </button>
          </div>
        </div> */}
      </main>
    </section>
  );
};
