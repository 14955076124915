// data rating & testimoni
export const ratingData = [
  {
    id: 1,
    rating: 5,
    name: "John Doe",
    variation: "xl",
    date: "05 Agustus 2024",
    comment: "Produk bagus",
  },
  {
    id: 2,
    rating: 4,
    name: "Sandra Smith",
    variation: "s",
    date: "03 Maret 2024",
    comment: "Produk bagus & keren",
  },
  {
    id: 3,
    rating: 5,
    name: "John Doe",
    variation: "Xl",
    date: "05 Agustus 2024",
    comment: "Produk bagus",
  },
];

export const EthicaProduct = {
  artikel: "AYUMI 254",
  stok: "2",
  brand: "ETHICA",
  sub_brand: "AYUMI",
  katalog: "03",
  tahun: "2021-01-01",
  tipe_brg: "A",
  jumlah_data: "1",
  list_warna: [
    {
      warna: "COKLAT",
      list_ukuran: [
        {
          ukuran: "S",
          stok: "2",
          seq: "12245",
          barcode: "103210140010007002",
          nama: "AYUMI 254 COKLAT S",
          brand: "ETHICA",
          sub_brand: "AYUMI",
          warna: "COKLAT",
          katalog: "03",
          tahun: "2021-01-01",
          tipe_brg: "A",
          harga: "297900",
          gambar:
            "http://103.139.175.102/ethica_api/public//uploads/ETHICA/AYUMI/TUMBNAIL/AYUMI 254 COKLAT.jpg",
          gambar_besar:
            "http://103.139.175.102/ethica_api/public//uploads/ETHICA/AYUMI/BESAR/AYUMI 254 COKLAT.jpg",
          gambar_sedang:
            "http://103.139.175.102/ethica_api/public//uploads/ETHICA/AYUMI/SEDANG/AYUMI 254 COKLAT.jpg",
          keterangan:
            "- Busui friendly\r\n- Wudhu friendly\r\n- Bukaan zipper\r\n- Variasi tali hidup di bagian pundak\r\n- Saku\r\n- Potongan variasi pada manset\r\n- Variasi plaket kancing",
          is_preorder: "F",
          tgl_release: "2022-03-08",
          klasifikasi: "Sapphire",
          berat: "0.55",
        },
        {
          ukuran: "M",
          stok: "5",
          seq: "12246",
          barcode: "103210140010007003",
          nama: "AYUMI 254 COKLAT M",
          brand: "ETHICA",
          sub_brand: "AYUMI",
          warna: "COKLAT",
          katalog: "03",
          tahun: "2021-01-01",
          tipe_brg: "A",
          harga: "307900",
          gambar:
            "http://103.139.175.102/ethica_api/public//uploads/ETHICA/AYUMI/TUMBNAIL/AYUMI 254 COKLAT.jpg",
          gambar_besar:
            "http://103.139.175.102/ethica_api/public//uploads/ETHICA/AYUMI/BESAR/AYUMI 254 COKLAT.jpg",
          gambar_sedang:
            "http://103.139.175.102/ethica_api/public//uploads/ETHICA/AYUMI/SEDANG/AYUMI 254 COKLAT.jpg",
          keterangan:
            "- Busui friendly\r\n- Wudhu friendly\r\n- Bukaan zipper\r\n- Variasi tali hidup di bagian pundak\r\n- Saku\r\n- Potongan variasi pada manset\r\n- Variasi plaket kancing",
          is_preorder: "F",
          tgl_release: "2022-03-08",
          klasifikasi: "Sapphire",
          berat: "0.55",
        },
      ],
      stok: "7",
    },
    {
      warna: "BIRU",
      list_ukuran: [
        {
          ukuran: "S",
          stok: "3",
          seq: "12247",
          barcode: "103210140010007004",
          nama: "AYUMI 254 BIRU S",
          brand: "ETHICA",
          sub_brand: "AYUMI",
          warna: "BIRU",
          katalog: "03",
          tahun: "2021-01-01",
          tipe_brg: "A",
          harga: "287900",
          gambar:
            "http://103.139.175.102/ethica_api/public//uploads/ETHICA/AYUMI/TUMBNAIL/AYUMI 254 BIRU.jpg",
          gambar_besar:
            "http://103.139.175.102/ethica_api/public//uploads/ETHICA/AYUMI/BESAR/AYUMI 254 BIRU.jpg",
          gambar_sedang:
            "http://103.139.175.102/ethica_api/public//uploads/ETHICA/AYUMI/SEDANG/AYUMI 254 BIRU.jpg",
          keterangan:
            "- Busui friendly\r\n- Wudhu friendly\r\n- Bukaan zipper\r\n- Variasi tali hidup di bagian pundak\r\n- Saku\r\n- Potongan variasi pada manset\r\n- Variasi plaket kancing",
          is_preorder: "F",
          tgl_release: "2022-03-08",
          klasifikasi: "Sapphire",
          berat: "0.55",
        },
        {
          ukuran: "M",
          stok: "4",
          seq: "12248",
          barcode: "103210140010007005",
          nama: "AYUMI 254 BIRU M",
          brand: "ETHICA",
          sub_brand: "AYUMI",
          warna: "BIRU",
          katalog: "03",
          tahun: "2021-01-01",
          tipe_brg: "A",
          harga: "407900",
          gambar:
            "http://103.139.175.102/ethica_api/public//uploads/ETHICA/AYUMI/TUMBNAIL/AYUMI 254 BIRU.jpg",
          gambar_besar:
            "http://103.139.175.102/ethica_api/public//uploads/ETHICA/AYUMI/BESAR/AYUMI 254 BIRU.jpg",
          gambar_sedang:
            "http://103.139.175.102/ethica_api/public//uploads/ETHICA/AYUMI/SEDANG/AYUMI 254 BIRU.jpg",
          keterangan:
            "- Busui friendly\r\n- Wudhu friendly\r\n- Bukaan zipper\r\n- Variasi tali hidup di bagian pundak\r\n- Saku\r\n- Potongan variasi pada manset\r\n- Variasi plaket kancing",
          is_preorder: "F",
          tgl_release: "2022-03-08",
          klasifikasi: "Sapphire",
          berat: "0.55",
        },
      ],
      stok: "7",
    },
  ],
};

export const dataArtikel = [
  {
    id: 1,
    title: "Artikel 1",
    date: "2022-01-01",
    img: "https://images.pexels.com/photos/1488463/pexels-photo-1488463.jpeg?auto=compress&cs=tinysrgb&w=600",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qua dolorem quidem inventore sed, voluptatibus saepe voluptatemnihil ut ipsum laborum ullam molestias neque eligendi isteeum, minus debitis temporibus maiores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis quod placeat nihil ducimus aut odit adipisci nulla earum, voluptatum iste. Fugitut earum illo rerum voluptatibus distinctio, neque error laudantium.",
  },
  {
    id: 2,
    title: "Artikel 1",
    date: "2022-01-01",
    img: "https://images.pexels.com/photos/1488463/pexels-photo-1488463.jpeg?auto=compress&cs=tinysrgb&w=600",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qua dolorem quidem inventore sed, voluptatibus saepe voluptatemnihil ut ipsum laborum ullam molestias neque eligendi isteeum, minus debitis temporibus maiores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis quod placeat nihil ducimus aut odit adipisci nulla earum, voluptatum iste. Fugitut earum illo rerum voluptatibus distinctio, neque error laudantium.",
  },
  {
    id: 3,
    title: "Artikel 1",
    date: "2022-01-01",
    img: "https://images.pexels.com/photos/1488463/pexels-photo-1488463.jpeg?auto=compress&cs=tinysrgb&w=600",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qua dolorem quidem inventore sed, voluptatibus saepe voluptatemnihil ut ipsum laborum ullam molestias neque eligendi isteeum, minus debitis temporibus maiores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis quod placeat nihil ducimus aut odit adipisci nulla earum, voluptatum iste. Fugitut earum illo rerum voluptatibus distinctio, neque error laudantium.",
  },
  {
    id: 4,
    title: "Artikel 1",
    date: "2022-01-01",
    img: "https://images.pexels.com/photos/1488463/pexels-photo-1488463.jpeg?auto=compress&cs=tinysrgb&w=600",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qua dolorem quidem inventore sed, voluptatibus saepe voluptatemnihil ut ipsum laborum ullam molestias neque eligendi isteeum, minus debitis temporibus maiores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis quod placeat nihil ducimus aut odit adipisci nulla earum, voluptatum iste. Fugitut earum illo rerum voluptatibus distinctio, neque error laudantium.",
  },
  {
    id: 5,
    title: "Artikel 1",
    date: "2022-01-01",
    img: "https://images.pexels.com/photos/1488463/pexels-photo-1488463.jpeg?auto=compress&cs=tinysrgb&w=600",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qua dolorem quidem inventore sed, voluptatibus saepe voluptatemnihil ut ipsum laborum ullam molestias neque eligendi isteeum, minus debitis temporibus maiores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis quod placeat nihil ducimus aut odit adipisci nulla earum, voluptatum iste. Fugitut earum illo rerum voluptatibus distinctio, neque error laudantium.",
  },
  {
    id: 6,
    title: "Artikel 1",
    date: "2022-01-01",
    img: "https://images.pexels.com/photos/1488463/pexels-photo-1488463.jpeg?auto=compress&cs=tinysrgb&w=600",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qua dolorem quidem inventore sed, voluptatibus saepe voluptatemnihil ut ipsum laborum ullam molestias neque eligendi isteeum, minus debitis temporibus maiores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis quod placeat nihil ducimus aut odit adipisci nulla earum, voluptatum iste. Fugitut earum illo rerum voluptatibus distinctio, neque error laudantium.",
  },
];

export const dataPembayaran = [
  {
    id: 1,
    img: "/img/bca.png",
    name: "Tina Rahmawati",
    no_rek: "68-2020-6493", // bca
  },
  {
    id: 2,
    img: "/img/bri.png",
    name: "Tina Rahmawati",
    no_rek: "1739-0100-0186-567", // bri
  },
  {
    id: 3,
    img: "/img/mandiri.png",
    name: "Tina Rahmawati",
    no_rek: "133-000-753-1841", // mandiri
  },
  {
    id: 4,
    img: "/img/bsi.png",
    name: "Tina Rahmawati",
    no_rek: "71-1641-2734", // bsi
  },
  {
    id: 5,
    img: "/img/niaga.png",
    name: "Tina Rahmawati",
    no_rek: "7622-6247-5800", // niaga
  },
];
