import React from "react";
import { X } from "react-feather";
// import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported

export default function Modal({ open, onClose, children }) {
  return (
      <>
          <div
              className={`modal ${open ? "show" : ""}`}
              style={{ display: open ? "block" : "none" }}
              tabIndex="-1"
              onClick={onClose}
          >
              <div className="modal-dialog modal-dialog-centered" onClick={(e) => e.stopPropagation()}>
                  <div className="modal-content">
                      <div className="modal-header">
                          <button type="button" className="btn-close" onClick={onClose} aria-label="Close"/>
                      </div>
                      <div className="modal-body">
                          {children}
                      </div>
                  </div>
              </div>
          </div>
          {open && <div className="modal-backdrop fade show"></div>}
      </>
  );
}