import React, { useEffect, useState } from "react";
import Logo from "./logo-delfia.png";
import Product  from "./product.jpeg";
import Product2  from "./product2.jpeg";
import Product3  from "./product3.jpeg";
import Product4  from "./product4.jpeg";
import Product5  from "./product5.jpeg";
import Product6  from "./product6.jpeg";
import Product7  from "./product7.jpeg";
import Banner  from "./banner.jpeg";
import iconHeart from '../../assets/imgs/theme/icons/icon-heart.svg'
import iconCart from '../../assets/imgs/theme/icons/icon-cart.svg'
import iconUser from '../../assets/imgs/theme/icons/icon-user.svg'
import { importAll } from "../../services/function";
import { fetchData } from "../../services/api.services";

const useMainCarousel = () => {
  const [mainCarousel, setMainCarousel] = useState([]);
  useEffect(()=>{
    fetchData('main/carousel', '', (data) => {
      const Carousel = data.data;
      setMainCarousel(Carousel.map(item=> item.image_url))
    });
  }, [])

  return mainCarousel;
};
const usePayement = () => {
  const [payment, setPayment] = useState([]);

  useEffect(()=>{
    const imgImport = importAll(require.context(`../../assets/images/payment`, false, /\.(png|jpe?g|svg)$/));
    setPayment(Object.values(imgImport)); 
  }, [])

  return payment;
};

const useShipping = () => {
  const [shipping, setShipping] = useState([]);

  useEffect(()=>{
    const imgImport = importAll(require.context(`../../assets/images/shipping`, false, /\.(png|jpe?g|svg)$/));
    setShipping(Object.values(imgImport)); 
  }, [])

  return shipping;
};

export {
  Logo,
  Product,
  Product2,
  Product3,
  Product4,
  Product5,
  Product6,
  Product7,
  Banner,
  iconHeart,
  iconCart,
  iconUser,
  useMainCarousel,
  usePayement,
  useShipping
};
