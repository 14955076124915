import React, { useEffect, useState } from "react";
import img404 from "../../assets/imgs/page/page-404.png";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { formatPrice } from "../../services/function";
import { userInfo } from "../Account/AuthProvider";
import { fetchData } from "../../services/api.services";
import { dataPembayaran } from "../../services/data";
import AlertComponent, { useAlert } from "../../components/commons/alert";

const PaymentOrder = () => {
  const [alert, showAlert] = useAlert();
  const location = useLocation();
  const isSuccess = location.pathname === "/payment-success";

  const dataSuccess = location.state.state.dataSuccess;
  React.useEffect(() => {
    if (!dataSuccess) {
      // Handle case where no data is found
      console.log("No payment success data found");
    }
  }, [dataSuccess]);

  const [footerSet, setFooterSet] = useState(null);

  useEffect(() => {
    fetchData("footer/menu", "", (data) => {
      setFooterSet(data.data.settings);
    });
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        // Optional: Show feedback to the user (e.g., Toast notification)
        showAlert("Teks telah disalin ke clipboard!");
      },
      (err) => {
        showAlert("Gagal menyalin teks: ", err);
      }
    );
  };

  return (
    <div>
      <main class="main page-404">
        <AlertComponent alert={alert} />
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <a
                class="btn float-end btn-default submit-auto-width font-xs hover-up mt-30"
                href="/"
              >
                <i class="fi-rs-home mr-5"></i> Back To Home Page
              </a>
            </div>
          </div>
        </div>

        <div class="page-content pt-150 pb-150">
          <div class="container">
            <div class="row">
              <div class="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
                {isSuccess ? (
                  <>
                    <FaCheckCircle class="text-info display-1" />
                    <h1 class="display-3 mb-30">Order Sukses</h1>
                    {dataSuccess && (
                      <p class="font-lg text-grey-700 mb-30">
                        Status:{" "}
                        <span id="totalPrice" className="fw-bolder text-info">
                          {userInfo ? "Pending" : "Lunas"}
                        </span>{" "}
                        <br />
                        Order ID: #
                        <span id="orderID" className="fw-bolder">
                          {dataSuccess.order_id && dataSuccess.id_order_ethica
                            ? `${dataSuccess.order_id} atau ${dataSuccess.id_order_ethica}`
                            : dataSuccess.order_id ||
                              dataSuccess.id_order_ethica}
                        </span>
                        <br />
                        Total Pesanan:{" "}
                        <span id="totalPrice" className="fw-bolder">
                          {formatPrice(dataSuccess.total)}
                        </span>
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <FaTimesCircle class="text-danger display-1" />
                    <h1 class="display-3 mb-30">Order Gagal</h1>
                    <p class="font-lg text-grey-700 mb-30">
                      Maaf, pembayaran Anda tidak berhasil. Silakan coba lagi.
                    </p>
                  </>
                )}
                <div
                  className="d-flex flex-column gap-2 p-2 w-100 mx-auto"
                  style={{ maxWidth: "400px" }}
                >
                  {dataPembayaran.map((item, index) => (
                    <div key={index} className="border-bottom p-1">
                      <div className="row">
                        <div className="col d-flex justify-content-center align-items-center mb-2 mb-md-0">
                          <img
                            src={item.img}
                            alt={item.name}
                            className="img-fluid"
                            style={{ maxWidth: "100px", height: "auto" }}
                          />
                        </div>

                        <div className="col d-flex flex-column align-items-center justify-content-center p-md-3">
                          <p className="fw-bold fs-6 text-dark">{item.name}</p>

                          <div className="d-flex align-items-center justify-content-center gap-1">
                            <p className="fw-bold fs-6 text-dark mb-0">
                              {item.no_rek}
                            </p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="black"
                              onClick={() => copyToClipboard(item.no_rek)}
                              style={{ cursor: "pointer", width: "20px" }}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {footerSet && (
                    <a
                      className="btn btn-success btn-block font-xs hover-up mt-3"
                      href={`https://api.whatsapp.com/send/?phone=${parseInt(
                        footerSet.whatsapp
                      )}&text=${encodeURIComponent(
                        `Konfirmasi pesanan dengan ID ${dataSuccess.order_id} `
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer" //  Menambahkan rel="noopener noreferrer" untuk keamanan
                      style={{ backgroundColor: "#26d466" }}
                    >
                      <i className="fa fa-whatsapp"></i> Konfirmasi Pesanan
                    </a>
                  )}
                </div>

                {/* <div className="d-flex justify-content-center flex-column gap-2 p-2 w-md-50 m-auto">
                  {dataPembayaran.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex justify-content-center align-items-center border-bottom p-2 bg-primary rounded-3"
                    >
                      <div className="row">
                        <div className="col d-flex justify-content-center align-items-center">
                          <img
                            src={item.img}
                            alt={item.name}
                            className="w-50 h-auto object-fit-cover"
                          />
                        </div>

                        <div className="col d-flex p-3 align-items-center justify-content-center flex-column">
                          <h5>{item.name}</h5>
                          <h5>{item.no_rek}</h5>
                        </div>
                      </div>
                    </div>
                  ))}

                  {footerSet && (
                    <a
                      class="btn submit-auto-width font-xs hover-up mt-30 w-30"
                      href={`https://api.whatsapp.com/send/?phone=${parseInt(
                        footerSet.whatsapp
                      )}&text=${encodeURIComponent(
                        `Konfirmasi pesanan dengan ID ${dataSuccess.order_id}`
                      )}`}
                      target="_blank"
                      style={{ backgroundColor: "#26d466" }}
                    >
                      <i className="fa fa-whatsapp"></i> Konfirmasi Pesanan
                    </a>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PaymentOrder;
