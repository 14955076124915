import React, { useEffect, useState } from "react";
import {
  Product,
  Product2,
  Product3,
  Product4,
  Product5,
  Product6,
  Product7,
} from "../../assets/images";
import {
  getCartEthicaTotalQuantity,
  getCartItems,
  getCartItemsEthica,
  getCartSubtotal,
  getCartSubtotalEthica,
  getCartTotalQuantity,
  updateCartItemQuantity,
} from "./Cart";
import { formatPrice } from "../../services/function";
import {
  apiFunctions,
  checkoutEthica,
  deleteCart,
  fetchCities,
  fetchCourier,
  fetchProvinces,
  fetchSubdistricts,
  getEthicaProductFromCart,
  getKurirEthica,
  getOngkirEthica,
  postData,
} from "../../services/api.services";
import { userInfo } from "../Account/AuthProvider";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import AlertComponent, { useAlert } from "../../components/commons/alert";

function Cart() {
  const history = useHistory();
  const [alert, showAlert] = useAlert();
  // Cart
  const [cartItems, setCartItems] = useState([]);
  const [cartItemsEthica, setCartItemsEthica] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [totalEthica, setTotalEthica] = useState(0);
  const [minimumPayment, setMinimumPayment] = useState(0);
  const [adminFee, setAdminFee] = useState(0);
  const [count, setCount] = useState(null);
  const [countEthica, setCountEthica] = useState(null);

  const calculateTotal = count + countEthica;

  const fetchCartData = () => {
    setCartItems(getCartItems());
    setSubtotal(getCartSubtotal());
    setCount(getCartTotalQuantity());
  };

  const calculateTotalEthica = (items) => {
    if (!Array.isArray(items)) {
      console.error("Items is not an array:", items);
      return 0;
    }
    return items.reduce(
      (total, item) => total + parseFloat(item.harga * item.qty_order),
      0
    );
  };

  const calculateQuantityEthica = (items) => {
    if (!Array.isArray(items)) {
      console.error("Items is not an array:", items);
      return 0;
    }
    return items.reduce((total, item) => total + parseInt(item.qty_order), 0);
  };

  useEffect(() => {
    apiFunctions.setting((data) => {
      setMinimumPayment(data.minimum_payment);
      setAdminFee(data.service_fee);
    });
  }, []);

  useEffect(() => {
    const fetchCartDataEthica = async () => {
      try {
        const response = await getEthicaProductFromCart();
        setCartItemsEthica(response);
      } catch (error) {
        console.error(error);
        return [];
      }
    };

    fetchCartData();
    fetchCartDataEthica();
    const handleCartUpdated = () => {
      fetchCartData();
      fetchCartDataEthica();
    };
    window.addEventListener("cartUpdated", handleCartUpdated);

    return () => {
      window.removeEventListener("cartUpdated", handleCartUpdated);
    };
  }, []);

  useEffect(() => {
    const ethicaTotal = calculateTotalEthica(cartItemsEthica);
    setCountEthica(calculateQuantityEthica(cartItemsEthica));
    setTotalEthica(ethicaTotal);
  }, [cartItemsEthica]);

  const handleQuantityChange = (itemId, increment) => {
    const updatedCart = cartItems
      .map((item) => {
        if (item.item_id === itemId) {
          const newQuantity = item.quantity + increment;
          if (newQuantity > 0 && newQuantity <= item.end_qty) {
            updateCartItemQuantity(itemId, newQuantity);
            return { ...item, quantity: newQuantity };
          } else if (newQuantity <= 0) {
            updateCartItemQuantity(itemId, newQuantity);
            return null; // Remove item from state
          } else {
            showAlert("Anda telah mencapai stok maksimum.", "danger");
          }
        }
        return item;
      })
      .filter((item) => item !== null);

    setCartItems(updatedCart);
    setSubtotal(getCartSubtotal());
    setCount(updatedCart.reduce((total, item) => total + item.quantity, 0));
  };
  const handleDeleteItem = (itemId) => {
    const updatedCart = cartItems.filter((item) => item.item_id !== itemId);
    localStorage.setItem("cart", JSON.stringify(updatedCart));

    setCartItems(updatedCart);
    setSubtotal(
      updatedCart.reduce(
        (total, item) => total + item.quantity * item.sell_price,
        0
      )
    );
    setCount(updatedCart.reduce((total, item) => total + item.quantity, 0));
  };

  const handleDeleteItemEthica = async (seq) => {
    const deleteItem = await deleteCart(seq);

    if (deleteItem) {
      const updatedCartItemsEthica = cartItemsEthica.filter(
        (item) => item.seq !== seq
      );
      localStorage.setItem(
        "cart-ethica",
        JSON.stringify(updatedCartItemsEthica)
      );
      setCartItemsEthica(updatedCartItemsEthica);
    }
  };

  // Ongkir & CO
  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [subdistricts, setSubdistricts] = useState([]);
  const [selectedSubdistrict, setSelectedSubdistrict] = useState(null);
  const [courier, setCourier] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [ExpedName, setExpedName] = useState([]);
  const [ExpedCost, setExpedCost] = useState([]);
  const [selectedExped, setSelectedExped] = useState(null);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalHeight, setTotalHeight] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [totalLength, setTotalLength] = useState(0);

  const [modalPurchase, setModalPurchase] = useState(false);
  const [SelectPaymentMethod, setPaymentMethod] =
    useState("adminPaymentMethod");
  const paymentMethod = userInfo ? SelectPaymentMethod : "midtrans";

  const [voucherCode, setVoucherCode] = useState("");
  const [resultVocher, setVocher] = useState(null);

  const handleBack = () => {
    setPaymentMethod("adminPaymentMethod");
  };
  const handleCancel = () => {
    history.push("/");
  };
  const handleVoucherChange = (e) => {
    setVoucherCode(e.target.value);
  };
  const handleCheckVoucher = () => {
    apiFunctions.voucher({ code: voucherCode, payment: subtotal }, (data) => {
      if (data.status === true) {
        setVocher(data.data);
        showAlert(data.message, "success");
      } else {
        showAlert(data.message, "danger");
        setVocher(null);
      }
    });
  };
  const [shippingDetails, setShippingDetails] = useState({
    shipping_full_name: "",
    shipping_email: "",
    shipping_phone: "",
    shipping_note: "",
    shipping_post_code: "",
    shipping_country: "Indonesia",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShippingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (selectedCity && selectedCity.postalCode) {
      setShippingDetails((prevDetails) => ({
        ...prevDetails,
        shipping_post_code: selectedCity.postalCode,
      }));
    }
  }, [selectedCity]);

  useEffect(() => {
    const loadCheckout = async () => {
      const data = await fetchProvinces();
      setProvinces(data);
      const dataCourier = await fetchCourier();
      setCourier(dataCourier);

      const storedCart = localStorage.getItem("cart");
      if (storedCart) {
        const totalweight = JSON.parse(storedCart).reduce((acc, item) => {
          return acc + parseInt(item.package_weight);
        }, 0);
        setTotalWeight(totalweight);
        const totalheight = JSON.parse(storedCart).reduce((acc, item) => {
          return acc + parseInt(item.package_height);
        }, 0);
        setTotalHeight(totalheight);
        const totalwidth = JSON.parse(storedCart).reduce((acc, item) => {
          return acc + parseInt(item.package_width);
        }, 0);
        setTotalWidth(totalwidth);
        const totallength = JSON.parse(storedCart).reduce((acc, item) => {
          return acc + parseInt(item.package_length);
        }, 0);
        setTotalLength(totallength);
      }
    };
    loadCheckout();
  }, []);
  const handleCourierChange = async (selectedOption) => {
    setSelectedCourier(selectedOption);
    if (selectedSubdistrict) {
      const endpoint = "ekspedisi/cost";
      const data = {
        origin: 1067,
        weight: totalWeight,
        height: totalHeight,
        width: totalWidth,
        length: totalLength,
        courier: selectedOption.value,
        destination: selectedSubdistrict.value,
      };

      try {
        const response = await postData(endpoint, data);
        setExpedName(response.data.rajaongkir.results[0].name);
        setExpedCost(response.data.rajaongkir.results[0].costs);
      } catch (error) {
        console.error("Error posting shipping details:", error);
      }
    }
  };
  const handleProvinceChange = async (selectedOption) => {
    setSelectedProvince(selectedOption);
    setSelectedCity(null);
    setSelectedSubdistrict(null);
    const citiesData = await fetchCities(selectedOption.value);
    setCities(citiesData);
  };
  const handleCityChange = async (selectedOption) => {
    setSelectedCity(selectedOption);
    setSelectedSubdistrict(null);
    const subdistrictsData = await fetchSubdistricts(selectedOption.value);
    setSubdistricts(subdistrictsData);
  };
  const handleSubsChange = async (selectedOption) => {
    setSelectedSubdistrict(selectedOption);
    setSelectedCourier(null);
  };
  const handleExpedChange = async (selectedOption) => {
    setSelectedExped(selectedOption);
  };
  const handlePayment = () => {
    setPaymentMethod("adminPaymentMethod");
    setModalPurchase(true);
  };

  const handleCompletePurchase = async () => {
    if (!userInfo) {
      if (
        !shippingDetails.shipping_full_name ||
        !shippingDetails.shipping_email ||
        !shippingDetails.shipping_phone ||
        !selectedProvince ||
        !selectedCity ||
        !selectedSubdistrict ||
        !selectedCourier ||
        !selectedExped
      ) {
        showAlert("Mohon lengkapi detail alamat!", "danger");
        return;
      }
    }
    // Construct items array from cartItems
    const items = cartItems.map((item) => ({
      item_id: item.item_id,
      serial_no: item.item_code,
      description: item.item_group_name + " - " + item.variant,
      tax_id: 1,
      price: item.sell_price,
      qty: item.quantity,
      disc: 0,
      disc_amount: 0,
      tax_amount: 0,
    }));

    const payload = {
      customer_name: shippingDetails.shipping_full_name,
      note: shippingDetails.shipping_note + " - " + selectedExped.label,
      source: 1,
      shipping_cost: selectedExped ? selectedExped.value : 0,
      is_paid: false,
      shipping_full_name: shippingDetails.shipping_full_name,
      shipping_phone: shippingDetails.shipping_phone,
      shipping_address: !userInfo
        ? selectedSubdistrict.label +
          ", " +
          selectedCity.label +
          ", " +
          selectedProvince.label
        : "-",
      shipping_post_code: shippingDetails.shipping_post_code,
      shipping_country: shippingDetails.shipping_country,
      total_disc: resultVocher
        ? resultVocher.is_percentage === true
          ? (subtotal * resultVocher.discount) / 100
          : resultVocher.discount
        : 0,
      // add_fee: !userInfo ? (subtotal <= minimumPayment ? adminFee : 0) : 0,
      add_fee: 0,
      add_disc: userInfo
        ? Math.max(
            (userInfo.user.role.discount / 100) * subtotal,
            (parseFloat(userInfo.user.special_discount) / 100) * subtotal
          )
        : 0,
      email: shippingDetails.shipping_email,
      payment_method: paymentMethod,
      items,
    };

    await apiFunctions.setting((data) => {
      setMinimumPayment(data.minimum_payment);
      setAdminFee(data.service_fee);
    });

    try {
      let response;
      // Send POST request to create order
      if (userInfo) {
        response = await postData("customer/order/createOrder", payload);
        if (response.data && response.data.status === 200) {
          const dataSuccess = response.data.data;
          localStorage.setItem("cart", JSON.stringify([]));
          history.push("/payment-success", { state: { dataSuccess } });
          window.location.href = "/payment-success";
        } else {
          console.error("Checkout Failed");
          window.location.href = "/payment-failed";
        }
      } else {
        const headers = {
          Authorization: `Bearer ${userInfo.access_token}`,
        };
        response = await postData("order/createOrder", payload, headers);
        if (response.data && response.data.status === 200) {
          const dataSuccess = response.data.data;
          localStorage.setItem("cart", JSON.stringify([]));
          history.push("/payment-success", { state: { dataSuccess } });
          window.location.href = "/payment-success";
        } else {
          console.error("Checkout Failed");
          window.location.href = "/payment-failed";
        }
      }
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  const [activeTab, setActiveTab] = useState("itemsProduct");

  // order keranjang ethica
  const [checkoutData, setCheckoutData] = useState({
    user_id: "",
    nama_kirim: "",
    no_telepon_kirim: "",
    alamat_kirim_lengkap: "",
    kelurahan: "",
  });
  const [provinsi, setProvinsi] = useState([]);
  const [selectedProvinsi, setSelectedProvinsi] = useState(null);
  const [kota, setKota] = useState([]);
  const [selectedKota, setSelectedKota] = useState(null);
  const [kecamatan, setKecamatan] = useState([]);
  const [selectedKecamatan, setSelectedKecamatan] = useState(null);
  const [kurir, setKurir] = useState([]);
  const [selectedKurir, setSelectedKurir] = useState(null);
  const [ekspedisi, setEkspedisi] = useState([]);
  const [hargaEkspedisi, setHargaEkspedisi] = useState([]);
  const [selectedEkspedisi, setSelectedEkspedisi] = useState(null);
  const [totalBerat, setTotalBerat] = useState(0);
  const [selectedOngkosKirim, setSelectedOngkosKirim] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (selectedEkspedisi) {
      setTotalPrice(totalEthica + (selectedEkspedisi?.value || 0));
    }
  }, [totalEthica, selectedEkspedisi]);

  useEffect(() => {
    if (selectedKota && selectedKota.postalCode) {
      setCheckoutData((prevDetails) => ({
        ...prevDetails,
        shipping_postal_code: selectedKota.postalCode,
      }));
    }
  }, [selectedKota]);

  useEffect(() => {
    const loadCheckout = async () => {
      const data = await fetchProvinces();
      setProvinsi(data);
      const dataCourier = await getKurirEthica();
      setKurir(dataCourier);

      const databarang = await getEthicaProductFromCart();
      if (Array.isArray(databarang)) {
        const total = databarang.reduce((acc, item) => {
          let berat = parseFloat(item.berat_total);

          // Pastikan berat tidak kurang dari 1
          if (!isNaN(berat) && berat < 1) {
            berat = 1;
          }

          if (!isNaN(berat)) {
            return acc + berat;
          } else {
            console.warn(`Invalid weight for item:`, item);
            return acc;
          }
        }, 0);
        setTotalBerat(total);
      } else {
        setTotalBerat(0); // Set berat to zero if there's no valid data
      }
    };
    loadCheckout();
  }, []);

  const handleInputEthicaChange = (e) => {
    const { name, value } = e.target;
    setCheckoutData((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const handleKurirChange = async (selectedOption) => {
    setSelectedKurir(selectedOption);

    if (selectedKecamatan) {
      const data = {
        origin: 1067, // Sesuaikan dengan nilai origin Delfia
        weight: totalBerat, // Berat dalam gram
        courier: selectedOption.value, // Kode kurir seperti INDAH, JET, dll.
        destination: selectedKecamatan.value, // Kode kecamatan atau tujuan
      };

      try {
        const response = await getOngkirEthica(data);
        if (response) {
          setEkspedisi(response.data[0].courier_code);
          setHargaEkspedisi(
            response.data.flatMap((service) =>
              service.services.map((item) => ({
                serviceCode: item.service_code,
                cost: item.cost,
              }))
            )
          );
        } else {
          console.error(
            "Kurir tidak ditemukan atau tidak tersedia untuk tujuan ini."
          );
        }
      } catch (error) {
        console.error("Error posting shipping details:", error.message);
      }
    }
  };

  const handleProvinsiChange = async (selectedOption) => {
    setSelectedProvinsi(selectedOption);
    setSelectedKota(null);
    setSelectedKecamatan(null);
    const citiesData = await fetchCities(selectedOption.value);
    setKota(citiesData);
  };
  const handleKotaChange = async (selectedOption) => {
    setSelectedKota(selectedOption);
    setSelectedKecamatan(null);
    const subdistrictsData = await fetchSubdistricts(selectedOption.value);
    setKecamatan(subdistrictsData);
  };
  const handleKecChange = async (selectedOption) => {
    setSelectedKecamatan(selectedOption);
    setSelectedKurir(null);
  };
  const handleEkpedisiChange = async (selectedOption) => {
    setSelectedEkspedisi(selectedOption);
    setSelectedOngkosKirim(selectedOption ? selectedOption.value : 0);
  };

  const handleCompletePurchaseEthica = async () => {
    if (!userInfo) {
      if (
        !checkoutData.nama_kirim ||
        !checkoutData.no_telepon_kirim ||
        !checkoutData.kelurahan ||
        !checkoutData.alamat_kirim_lengkap ||
        !selectedProvinsi ||
        !selectedKota ||
        !selectedKecamatan ||
        !selectedKurir ||
        !selectedEkspedisi
      ) {
        showAlert("Mohon lengkapi detail alamat!", "danger");
        return;
      }
    }
    // Construct items array from cartItems
    const items = cartItemsEthica.map((item) => ({
      keranjang_seq: item.seq,
    }));

    const payload = {
      user_id: userInfo ? userInfo.user.id : null,
      nama_kirim: checkoutData.nama_kirim,
      no_telepon_kirim: checkoutData.no_telepon_kirim,
      alamat_kirim_lengkap: checkoutData.alamat_kirim_lengkap,
      id_provinsi: selectedProvinsi.value,
      id_kota: selectedKota.value,
      id_kecamatan: selectedKecamatan.value,
      provinsi: selectedProvinsi.label,
      kota: selectedKota.label,
      kecamatan: selectedKecamatan.label,
      kelurahan: checkoutData.kelurahan,
      nama_pengirim: "Delfia",
      alamat_pengirim_lengkap: "",
      no_telepon_pengirim: "082210109967",
      code: selectedKurir ? selectedKurir.value : null,
      service: selectedEkspedisi ? selectedEkspedisi.service : null,
      detail: items,
      ongkos_kirim: selectedOngkosKirim,
      total_price: totalPrice,
    };

    console.log(payload);

    // Get minimum payment and admin fee
    await apiFunctions.setting((data) => {
      setMinimumPayment(data.minimum_payment);
      setAdminFee(data.service_fee);
    });

    try {
      let response;
      if (!userInfo) {
        const response = await checkoutEthica(payload);
        if (response && response.data["midtrans-token"]) {
          const token = response.data.data["midtrans-token"];
          let minimum = 0;
          let admin = 0;
          await apiFunctions.setting((data) => {
            setMinimumPayment(data.minimum_payment);
            setAdminFee(data.service_fee);

            minimum = data.minimum_payment;
            admin = data.service_fee;
          });

          window.snap.pay(token, {
            onSuccess: async function (result) {
              const orderSuccessResponse = await postData(
                "customer/order/successOrder",
                {
                  token: token,
                }
              );
              const dataSuccess = {
                order_id: orderSuccessResponse.data.data.order_id,
                totalPrice:
                  selectedExped && selectedExped !== null
                    ? formatPrice(
                        selectedExped.value -
                          (resultVocher
                            ? resultVocher.is_percentage === true
                              ? (subtotal * resultVocher.discount) / 100
                              : resultVocher.discount
                            : 0) +
                          (subtotal <= minimum ? admin : 0)
                      )
                    : formatPrice(
                        subtotal -
                          (resultVocher
                            ? resultVocher.is_percentage === true
                              ? (subtotal * resultVocher.discount) / 100
                              : resultVocher.discount
                            : 0) +
                          (subtotal <= minimum ? admin : 0)
                      ),
              };
              localStorage.setItem("cart", JSON.stringify([]));
              history.push("/payment-success", { state: { dataSuccess } });
              window.location.href = `/payment-success`;
            },
            onPending: function (result) {
              console.log("Payment pending", result);
            },
            onError: function (result) {
              console.error("Payment error", result);
              window.location.href = "/payment-failed";
            },
            onClose: function () {
              console.log("Popup closed without completing payment");
            },
          });
        } else {
          console.error("No Midtrans token found in response");
        }
      } else {
        const response = await checkoutEthica(payload);
        console.log(response);
        if (response && response.status === "success") {
          const dataSuccess = response.data;
          localStorage.setItem("cart-ethica", JSON.stringify([]));
          history.push("/payment-success", { state: { dataSuccess } });
          window.location.href = "/payment-success";
        } else {
          console.error("Checkout Failed");
          window.location.href = "/payment-failed";
        }
      }
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  return (
    <section id="cart">
      <AlertComponent alert={alert} />
      <main class="main">
        <div class="page-header breadcrumb-wrap">
          <div class="container">
            <div class="breadcrumb">
              <a href="/" rel="nofollow">
                <i class="fi-rs-home mr-5"></i>Beranda
              </a>
              <span></span> Shop
              <span></span> Cart
            </div>
          </div>
        </div>
        <div class="container mb-80 mt-50">
          <div class="row">
            <div class="col-lg-8 mb-40">
              <h1 class="heading-2 mb-10">Keranjang Belanja</h1>
              <div class="d-flex justify-content-between">
                <h6 class="text-body">
                  Terdapat <span class="text-brand">{calculateTotal}</span>{" "}
                  produk di keranjang anda
                </h6>
                {/* <h6 class="text-body"><a href="#" class="text-muted"><i class="fi-rs-trash mr-5"></i>Clear Cart</a></h6> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <ul className="nav nav-tabs pb-2">
                <li className="nav-item w-50 text-center">
                  <a
                    className={`nav-link ${
                      activeTab === "itemsProduct" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("itemsProduct")}
                  >
                    Keranjang
                  </a>
                </li>
                <li className="nav-item w-50 text-center">
                  <a
                    className={`nav-link ${
                      activeTab === "ethicaProduct" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("ethicaProduct")}
                  >
                    Keranjang Ethica
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content">
            {/* keranjang jubelio */}
            <div
              className={`tab-pane ${
                activeTab === "itemsProduct" ? "active" : ""
              }`}
            >
              <div class="row">
                <div class="col-lg-8">
                  <div class="table-responsive shopping-summery">
                    <table class="table table-wishlist">
                      <thead>
                        <tr class="main-heading">
                          <th class="start pl-30" scope="col" colspan="2">
                            Produk
                          </th>
                          <th scope="col">Harga Satuan</th>
                          <th scope="col">Jumlah</th>
                          <th scope="col">Subtotal</th>
                          <th scope="col" class="end">
                            Hapus
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartItems.map((item) => (
                          <tr class="pt-30">
                            <td class="image product-thumbnail pt-40 pl-30">
                              <img
                                src={
                                  item.images.length > 0
                                    ? item.images[0].cloud_key
                                    : ""
                                }
                                alt={item.item_code}
                              />
                            </td>
                            <td class="product-des product-name">
                              <h6 class="mb-5">
                                <a
                                  class="product-name mb-10 text-heading"
                                  href="shop-product-right.html"
                                >
                                  {item.item_name}
                                </a>
                              </h6>
                              <div class="product-rate-cover">
                                <span class="font-small text-muted">
                                  {item.item_code}
                                </span>
                              </div>
                            </td>
                            <td class="price" data-title="Price">
                              <h4 class="text-body">
                                {formatPrice(item.sell_price)}
                              </h4>
                            </td>
                            <td
                              class="text-center detail-info"
                              data-title="Stock"
                            >
                              <div class="detail-extralink mr-15">
                                <div class="detail-qty border radius">
                                  {/* <a href="#" class="qty-down"><i class="fi-rs-angle-small-down"></i></a> */}
                                  <a
                                    className="qty-down"
                                    onClick={() =>
                                      handleQuantityChange(item.item_id, -1)
                                    }
                                  >
                                    <i className="fi-rs-angle-small-down"></i>
                                  </a>
                                  <input
                                    type="text"
                                    name="quantity"
                                    class="qty-val"
                                    value={item.quantity}
                                    min="1"
                                  />
                                  <a
                                    className="qty-up"
                                    onClick={() =>
                                      handleQuantityChange(item.item_id, 1)
                                    }
                                  >
                                    <i className="fi-rs-angle-small-up"></i>
                                  </a>
                                  {/* <a href="#" class="qty-up"><i class="fi-rs-angle-small-up"></i></a> */}
                                </div>
                              </div>
                            </td>
                            <td class="price" data-title="Price">
                              <h4 class="text-brand">
                                {formatPrice(item.sell_price * item.quantity)}
                              </h4>
                            </td>
                            <td class="action text-center" data-title="Remove">
                              <a
                                onClick={() => handleDeleteItem(item.item_id)}
                                class="text-body"
                              >
                                <i class="fi-rs-trash"></i>
                              </a>
                              {/* <a href="#" class="text-body"><i class="fi-rs-trash"></i></a> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="divider-2 mb-30 mt-20"></div>

                  <div class="row">
                    <h4 class="mb-30">Detail Alamat</h4>
                    <form method="post">
                      <div class="row">
                        <div class="form-group col-lg-12">
                          <input
                            type="text"
                            required=""
                            name="shipping_full_name"
                            placeholder="Nama Lengkap *"
                            value={shippingDetails.shipping_full_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-lg-6">
                          <input
                            required=""
                            type="text"
                            name="shipping_phone"
                            placeholder="Whatsapp, Ex:+6281234567890 *"
                            value={shippingDetails.shipping_phone}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="form-group col-lg-6">
                          <input
                            required=""
                            type="email"
                            name="shipping_email"
                            placeholder="Email *"
                            value={shippingDetails.shipping_email}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div class="row shipping_calculator">
                        <div class="form-group col-lg-6">
                          <div class="custom_select">
                            <select
                              className="form-control select-active"
                              value={
                                selectedProvince ? selectedProvince.value : ""
                              }
                              onChange={(e) => {
                                const selectedOption = provinces.find(
                                  (province) =>
                                    province.province_id === e.target.value
                                );
                                handleProvinceChange(
                                  selectedOption
                                    ? {
                                        value: selectedOption.province_id,
                                        label: selectedOption.province,
                                      }
                                    : null
                                );
                              }}
                            >
                              <option value="">Pilih Provinsi *</option>
                              {provinces.map((province) => (
                                <option
                                  key={province.province_id}
                                  value={province.province_id}
                                >
                                  {province.province}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="form-group col-lg-6">
                          <div class="custom_select">
                            <select
                              className="form-control select-active"
                              value={selectedCity ? selectedCity.value : ""}
                              onChange={(e) => {
                                const selectedOption = cities.find(
                                  (city) => city.city_id === e.target.value
                                );
                                handleCityChange(
                                  selectedOption
                                    ? {
                                        value: selectedOption.city_id,
                                        label: `${selectedOption.city_name} (${selectedOption.type})`,
                                        postalCode: selectedOption.postal_code,
                                      }
                                    : null
                                );
                              }}
                            >
                              <option value="">Pilih Kota / Kabupaten *</option>
                              {cities.map((city) => (
                                <option key={city.city_id} value={city.city_id}>
                                  {`${city.city_name} (${city.type})`}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-lg-6">
                          <div class="custom_select">
                            <select
                              className="form-control select-active"
                              value={
                                selectedSubdistrict
                                  ? selectedSubdistrict.value
                                  : ""
                              }
                              onChange={(e) => {
                                const selectedOption = subdistricts.find(
                                  (subdistrict) =>
                                    subdistrict.subdistrict_id ===
                                    e.target.value
                                );
                                handleSubsChange(
                                  selectedOption
                                    ? {
                                        value: selectedOption.subdistrict_id,
                                        label: selectedOption.subdistrict_name,
                                      }
                                    : null
                                );
                              }}
                            >
                              <option value="">Pilih Kecamatan *</option>
                              {subdistricts.map((subdistrict) => (
                                <option
                                  key={subdistrict.subdistrict_id}
                                  value={subdistrict.subdistrict_id}
                                >
                                  {subdistrict.subdistrict_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="form-group col-lg-6">
                          <div class="custom_select">
                            <select
                              className="form-control select-active text-uppercase"
                              value={
                                selectedCourier ? selectedCourier.value : ""
                              }
                              onChange={(e) => {
                                const selectedOption = courier.find(
                                  (courier) => courier === e.target.value
                                );
                                handleCourierChange(
                                  selectedOption
                                    ? {
                                        value: selectedOption,
                                        label: selectedOption,
                                      }
                                    : null
                                );
                              }}
                            >
                              <option value="">Pilih Kurir *</option>
                              {courier.map((courier, index) => (
                                <option key={index} value={courier}>
                                  {courier}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {/* <div class="form-group col-lg-6">
                                    <input required="" type="text" name="zipcode" placeholder="Postcode / ZIP *" value={selectedCity.postalCode} disabled/>
                                </div> */}
                      </div>
                      {selectedCourier && (
                        <div class="row">
                          <div class="form-group col-lg-12">
                            <div class="custom_select">
                              <select
                                className="form-control select-active"
                                value={selectedExped ? selectedExped.value : ""}
                                onChange={(e) => {
                                  const selectedOption = ExpedCost.find(
                                    (cost) =>
                                      cost.cost[0].value ===
                                      parseInt(e.target.value)
                                  );
                                  handleExpedChange(
                                    selectedOption
                                      ? {
                                          value: selectedOption.cost[0].value,
                                          label: `${ExpedName} - ${selectedOption.service}`,
                                        }
                                      : null
                                  );
                                }}
                              >
                                <option value="">
                                  Pilih Kurir Ekspedisi *
                                </option>
                                {ExpedCost.map((data, index) => (
                                  <option
                                    key={index}
                                    value={data.cost[0].value}
                                  >
                                    {`${ExpedName} - ${
                                      data.service
                                    } - ${formatPrice(data.cost[0].value)}`}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}

                      <div class="form-group mb-30">
                        <textarea
                          rows="5"
                          placeholder="Informasi Tambahan"
                          name="shipping_note"
                          value={shippingDetails.shipping_note}
                          onChange={handleInputChange}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="border p-md-4 cart-totals ml-30">
                    <div class="table-responsive">
                      <table class="table no-border">
                        <tbody>
                          <tr>
                            <td className="cart_total_label">
                              <h6 className="text-muted">Subtotal</h6>
                            </td>
                            <td className="cart_total_amount">
                              <h4 className="text-brand text-end">
                                {formatPrice(subtotal)}
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <td scope="col" colSpan="2">
                              <div className="divider-2 mt-10 mb-10"></div>
                            </td>
                          </tr>
                          <tr>
                            <td className="cart_total_label">
                              <h6 className="text-muted">Ongkir</h6>
                            </td>
                            <td className="cart_total_amount">
                              <h5 class="text-heading text-end">
                                {selectedExped && selectedExped !== null
                                  ? formatPrice(selectedExped.value)
                                  : "Isi Alamat Pengiriman"}
                              </h5>
                            </td>
                          </tr>
                          {userInfo && (
                            <>
                              <tr>
                                <td scope="col" colSpan="2">
                                  <div className="divider-2 mt-10 mb-10"></div>
                                </td>
                              </tr>
                              <tr>
                                <td className="cart_total_label">
                                  <h6 className="text-muted">
                                    Diskon {userInfo.user.role.name}
                                  </h6>
                                </td>
                                <td className="cart_total_amount">
                                  <h4 className="text-brand text-end">
                                    {userInfo
                                      ? `${Math.max(
                                          userInfo.user.role.discount,
                                          parseFloat(
                                            userInfo.user.special_discount
                                          )
                                        )}%`
                                      : "0%"}
                                  </h4>
                                </td>
                              </tr>
                            </>
                          )}
                          {/* {!userInfo && (
                            <>
                              <tr>
                                <td scope="col" colSpan="2">
                                  <div className="divider-2 mt-10 mb-10"></div>
                                </td>
                              </tr>
                              <tr>
                                <td className="cart_total_label">
                                  <h6 className="text-muted">Biaya Admin</h6>
                                </td>
                                <td className="cart_total_amount">
                                  <h4 className="text-brand text-end">
                                    {subtotal <= minimumPayment ? adminFee : 0}
                                  </h4>
                                </td>
                              </tr>
                            </>
                          )} */}
                          <tr>
                            <td scope="col" colSpan="2">
                              <div className="divider-2 mt-10 mb-10"></div>
                            </td>
                          </tr>
                          <tr>
                            <td className="cart_total_label">
                              <h6 className="text-muted">Total</h6>
                            </td>
                            <td className="cart_total_amount">
                              <h4 className="text-brand text-end">
                                {selectedExped && selectedExped !== null
                                  ? userInfo
                                    ? formatPrice(
                                        subtotal +
                                          selectedExped.value -
                                          Math.max(
                                            (subtotal *
                                              userInfo.user.role.discount) /
                                              100,
                                            (subtotal *
                                              parseFloat(
                                                userInfo.user.special_discount
                                              )) /
                                              100
                                          )
                                      )
                                    : formatPrice(
                                        subtotal +
                                          selectedExped.value +
                                          (subtotal <= minimumPayment
                                            ? adminFee
                                            : 0)
                                      )
                                  : userInfo
                                  ? formatPrice(
                                      subtotal -
                                        Math.max(
                                          (subtotal *
                                            userInfo.user.role.discount) /
                                            100,
                                          (subtotal *
                                            parseFloat(
                                              userInfo.user.special_discount
                                            )) /
                                            100
                                        )
                                    )
                                  : formatPrice(
                                      subtotal +
                                        (subtotal <= minimumPayment
                                          ? adminFee
                                          : 0)
                                    )}
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <a class="btn mb-20 w-100" onClick={handleCompletePurchase}>
                      Proses CheckOut<i class="fi-rs-sign-out ml-15"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* keranjang ethica */}
            <div
              className={`tab-pane ${
                activeTab === "ethicaProduct" ? "active" : ""
              }`}
            >
              <div class="row">
                <div class="col-lg-8">
                  <div class="table-responsive shopping-summery">
                    <table class="table table-wishlist">
                      <thead>
                        <tr class="main-heading">
                          <th class="start pl-30" scope="col" colspan="2">
                            Produk
                          </th>
                          <th scope="col">Harga Satuan</th>
                          <th scope="col">Jumlah</th>
                          <th scope="col">Subtotal</th>
                          <th scope="col" class="end">
                            Hapus
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(cartItemsEthica) &&
                        cartItemsEthica.length > 0 ? (
                          <>
                            {cartItemsEthica.map((item) => (
                              <tr className="pt-30" key={item.seq}>
                                <td className="image product-thumbnail pt-40 pl-30">
                                  <img src={item.gambar} alt={item.gambar} />
                                </td>
                                <td className="product-des product-name">
                                  <h6 className="mb-5">
                                    <a
                                      className="product-name mb-10 text-heading"
                                      href="shop-product-right.html"
                                    >
                                      {item.nama}
                                    </a>
                                  </h6>
                                  <div className="product-rate-cover">
                                    <span className="font-small text-muted">
                                      {item.nama}
                                    </span>
                                  </div>
                                </td>
                                <td className="price" data-title="Price">
                                  <h4 className="text-body">
                                    {formatPrice(parseFloat(item.harga))}
                                  </h4>
                                </td>
                                <td
                                  className="text-center detail-info"
                                  data-title="Stock"
                                >
                                  <div className="detail-extralink mr-15">
                                    <div className="detail-qty border radius">
                                      <input
                                        type="text"
                                        name="quantity"
                                        className="qty-val"
                                        value={item.qty_order}
                                        min="1"
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td className="price" data-title="Price">
                                  <h4 className="text-brand">
                                    {formatPrice(
                                      parseFloat(item.harga) *
                                        parseInt(item.qty_order)
                                    )}
                                  </h4>
                                </td>
                                <td
                                  className="action text-center"
                                  data-title="Remove"
                                >
                                  <a
                                    className="text-body"
                                    onClick={() =>
                                      handleDeleteItemEthica(item.seq)
                                    }
                                  >
                                    <i className="fi-rs-trash"></i>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div class="divider-2 mb-30 mt-20"></div>

                  <div class="row">
                    <h4 class="mb-30">Detail Alamat</h4>
                    <form method="post">
                      <div class="row">
                        <div class="form-group col-lg-12">
                          <input
                            type="text"
                            required=""
                            name="nama_kirim"
                            placeholder="Nama Lengkap *"
                            value={checkoutData.nama_kirim}
                            onChange={handleInputEthicaChange}
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-lg-6">
                          <input
                            required=""
                            type="text"
                            name="no_telepon_kirim"
                            placeholder="Whatsapp, Ex:+6281234567890 *"
                            value={checkoutData.no_telepon_kirim}
                            onChange={handleInputEthicaChange}
                          />
                        </div>
                        <div class="form-group col-lg-6">
                          <div class="custom_select">
                            <select
                              className="form-control select-active"
                              value={
                                selectedProvinsi ? selectedProvinsi.value : ""
                              }
                              onChange={(e) => {
                                const selectedOption = provinsi.find(
                                  (province) =>
                                    province.province_id === e.target.value
                                );
                                handleProvinsiChange(
                                  selectedOption
                                    ? {
                                        value: selectedOption.province_id,
                                        label: selectedOption.province,
                                      }
                                    : null
                                );
                              }}
                            >
                              <option value="">Pilih Provinsi *</option>
                              {provinsi.map((province) => (
                                <option
                                  key={province.province_id}
                                  value={province.province_id}
                                >
                                  {province.province}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row shipping_calculator">
                        <div class="form-group col-lg-6">
                          <div class="custom_select">
                            <select
                              className="form-control select-active"
                              value={selectedKota ? selectedKota.value : ""}
                              onChange={(e) => {
                                const selectedOption = kota.find(
                                  (city) => city.city_id === e.target.value
                                );
                                handleKotaChange(
                                  selectedOption
                                    ? {
                                        value: selectedOption.city_id,
                                        label: `${selectedOption.city_name} (${selectedOption.type})`,
                                        postalCode: selectedOption.postal_code,
                                      }
                                    : null
                                );
                              }}
                            >
                              <option value="">Pilih Kota / Kabupaten *</option>
                              {kota.map((city) => (
                                <option key={city.city_id} value={city.city_id}>
                                  {`${city.city_name} (${city.type})`}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="form-group col-lg-6">
                          <div class="custom_select">
                            <select
                              className="form-control select-active"
                              value={
                                selectedKecamatan ? selectedKecamatan.value : ""
                              }
                              onChange={(e) => {
                                const selectedOption = kecamatan.find(
                                  (subdistrict) =>
                                    subdistrict.subdistrict_id ===
                                    e.target.value
                                );
                                handleKecChange(
                                  selectedOption
                                    ? {
                                        value: selectedOption.subdistrict_id,
                                        label: selectedOption.subdistrict_name,
                                      }
                                    : null
                                );
                              }}
                            >
                              <option value="">Pilih Kecamatan *</option>
                              {kecamatan.map((subdistrict) => (
                                <option
                                  key={subdistrict.subdistrict_id}
                                  value={subdistrict.subdistrict_id}
                                >
                                  {subdistrict.subdistrict_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-lg-6">
                          <input
                            required=""
                            type="text"
                            name="kelurahan"
                            placeholder="Kelurahan *"
                            value={checkoutData.kelurahan}
                            onChange={handleInputEthicaChange}
                          />
                        </div>
                        <div class="form-group col-lg-6">
                          <div class="custom_select">
                            <select
                              className="form-control select-active text-uppercase"
                              value={selectedKurir ? selectedKurir.value : ""}
                              onChange={(e) => {
                                const selectedOption = kurir.find(
                                  (courier) =>
                                    courier.kode.toLowerCase() ===
                                    e.target.value.toLowerCase()
                                );
                                handleKurirChange(
                                  selectedOption
                                    ? {
                                        value:
                                          selectedOption.kode.toLowerCase(),
                                        label: selectedOption.nama,
                                      }
                                    : null
                                );
                              }}
                            >
                              <option value="">Pilih Kurir *</option>
                              {kurir.map((courier, index) => (
                                <option
                                  key={index}
                                  value={courier.kode.toLowerCase()}
                                >
                                  {courier.nama}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      {selectedKurir && (
                        <div class="row">
                          <div class="form-group col-lg-12">
                            <div class="custom_select">
                              <select
                                className="form-control select-active"
                                value={
                                  selectedEkspedisi
                                    ? selectedEkspedisi.value
                                    : ""
                                }
                                onChange={(e) => {
                                  const selectedOption = hargaEkspedisi.find(
                                    (option) =>
                                      option.cost &&
                                      option.cost[0] &&
                                      option.cost[0].value ===
                                        parseInt(e.target.value)
                                  );
                                  handleEkpedisiChange(
                                    selectedOption
                                      ? {
                                          value: selectedOption.cost[0].value,
                                          label: selectedOption.serviceCode,
                                          service: selectedOption.serviceCode,
                                        }
                                      : null
                                  );
                                }}
                              >
                                <option value="">
                                  Pilih Kurir Ekspedisi *
                                </option>
                                {hargaEkspedisi.map((data, index) => (
                                  <option
                                    key={index}
                                    value={
                                      data.cost[0] ? data.cost[0].value : ""
                                    }
                                  >
                                    {`${ekspedisi} - ${
                                      data.serviceCode
                                    } - ${formatPrice(data.cost[0].value)}`}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}

                      <div class="form-group mb-30">
                        <textarea
                          rows="4"
                          placeholder="alamat lengkap *"
                          name="alamat_kirim_lengkap"
                          value={checkoutData.alamat_kirim_lengkap}
                          onChange={handleInputEthicaChange}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="border p-md-4 cart-totals ml-30">
                    <div class="table-responsive">
                      <table class="table no-border">
                        <tbody>
                          <tr>
                            <td className="cart_total_label">
                              <h6 className="text-muted">Subtotal</h6>
                            </td>
                            <td className="cart_total_amount">
                              <h4 className="text-brand text-end">
                                {formatPrice(totalEthica)}
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <td scope="col" colSpan="2">
                              <div className="divider-2 mt-10 mb-10"></div>
                            </td>
                          </tr>
                          <tr>
                            <td className="cart_total_label">
                              <h6 className="text-muted">Ongkir</h6>
                            </td>
                            <td className="cart_total_amount">
                              <h5 class="text-heading text-end">
                                {selectedEkspedisi && selectedEkspedisi !== null
                                  ? formatPrice(selectedEkspedisi.value)
                                  : "Isi Alamat Pengiriman"}
                              </h5>
                            </td>
                          </tr>

                          <tr>
                            <td scope="col" colSpan="2">
                              <div className="divider-2 mt-10 mb-10"></div>
                            </td>
                          </tr>
                          <tr>
                            <td className="cart_total_label">
                              <h6 className="text-muted">Total</h6>
                            </td>
                            <td className="cart_total_amount">
                              <h4 className="text-brand text-end">
                                {selectedEkspedisi && selectedEkspedisi !== null
                                  ? formatPrice(totalPrice)
                                  : formatPrice(totalEthica)}
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <a
                      class="btn mb-20 w-100"
                      onClick={handleCompletePurchaseEthica}
                    >
                      Proses CheckOut<i class="fi-rs-sign-out ml-15"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
}

export default Cart;
