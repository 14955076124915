import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const QuillEditor = ({ value, onChange }) => {
  const handleChange = (html) => {
    onChange(html);
  };

  return (
    <div>
      <ReactQuill
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default QuillEditor;