import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { userInfo } from "./AuthProvider";
import {
  apiFunctions,
  getDiskonMember,
  getEthicaOrder,
  postData,
  updateData,
  urlAPI,
} from "../../services/api.services";
import { getDetailPesanan } from "../../services/api.services";
import AlertComponent, { useAlert } from "../../components/commons/alert";

export const ModalComponent = ({
  showModal,
  handleClose,
  activeTab,
  editMode,
  editData,
  setEditData,
  showAlert,
}) => {
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    password: "",
    phone: "",

    title: "",
    image_url: null,
    url: "",

    category_id: "",
    pageView: "",
    is_image_active: 0,
    is_video_active: 0,
    is_button_active: 0,
    button_text: "",
    button_link: "",
    video_url: null,
  });
  useEffect(() => {
    if (editData && Object.keys(editData).length > 0) {
      setFormData({
        name: editData.name || "",
        username: editData.username || "",
        password: "",
        phone: editData.phone || "",

        title: editData.title || "",
        image_url: editData.image_url || null,
        url: editData.url || "",

        category_id: editData.category_id || "",
        pageView: editData.pageView || "",
        is_image_active: editData.is_image_active ? 1 : 0,
        is_video_active: editData.is_video_active ? 1 : 0,
        is_button_active: editData.is_button_active ? 1 : 0,
        button_text: editData.button_text || "",
        button_link: editData.button_link || "",
        video_url: editData.video_url || null,
      });
    } else {
      setFormData({
        name: "",
        username: "",
        password: "",
        phone: "",

        title: "",
        image_url: null,

        category_id: "",
        pageView: "",
        is_image_active: 0,
        is_video_active: 0,
        is_button_active: 0,
        button_text: "",
        button_link: "",
        video_url: null,
      });
    }
    apiFunctions.category((data) => {
      setCategories(data);
    });
  }, [editData]);

  const handleFormChange = (e) => {
    const { id, value, files, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]:
        type === "file"
          ? files[0]
          : type === "checkbox"
          ? checked
            ? 1
            : 0
          : value,
    }));
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Bearer " + userInfo.access_token,
    };

    let endpoint = "";
    let apiFunction = null;
    const apiData = new FormData();
    Object.keys(formData).forEach((key) => {
      apiData.append(key, formData[key]);
    });

    switch (activeTab) {
      case "Admin":
        if (editMode && editData && editData.id) {
          endpoint = `user/update/${editData.id}`;
          apiFunction = updateData;
        } else {
          endpoint = "user/store";
          apiFunction = postData;
        }
        // apiData = formData;
        break;
      case "Carousel":
        if (editMode && editData && editData.id) {
          endpoint = `main/carousel/update/${editData.id}?_method=PUT`;
          apiFunction = postData;
        } else {
          endpoint = "main/carousel/store";
          apiFunction = postData;
        }
        // apiData = formData;
        break;
      case "Metode Pembayaran":
        if (editMode && editData && editData.id) {
          endpoint = `footer/payment/update/${editData.id}?_method=PUT`;
          apiFunction = postData;
        } else {
          endpoint = "footer/payment/store";
          apiFunction = postData;
        }
        break;
      case "Metode Pengiriman":
        if (editMode && editData && editData.id) {
          endpoint = `footer/shipment/update/${editData.id}?_method=PUT`;
          apiFunction = postData;
        } else {
          endpoint = "footer/shipment/store";
          apiFunction = postData;
        }
        break;
      case "Tampilan Produk":
        if (editMode && editData && editData.id) {
          endpoint = `main/section/update/${editData.id}?_method=PUT`;
          apiFunction = postData;
        } else {
          endpoint = "main/section/store";
          apiFunction = postData;
        }
        break;
      case "Menu Bawah":
        if (editMode && editData && editData.id) {
          endpoint = `footer/menu/update/${editData.id}?_method=PUT`;
          apiFunction = postData;
        } else {
          endpoint = "footer/menu/store";
          apiFunction = postData;
        }
        break;
      default:
        return;
    }

    try {
      const response = await apiFunction(endpoint, apiData, headers);

      if ((response && response.status === 200) || response.status === 201) {
        showAlert(
          `${activeTab} berhasil ${editMode ? "diperbarui" : "ditambahkan"}`,
          "success"
        );
        handleClose();
        // Lakukan pembaruan state atau aksi tambahan jika diperlukan
      } else {
        showAlert(
          `${activeTab} gagal ${editMode ? "diperbarui" : "ditambahkan"}`,
          "danger"
        );
      }
    } catch (error) {
      console.error("Error:", error);
      showAlert(
        `${activeTab} gagal ${editMode ? "diperbarui" : "ditambahkan"}`,
        "danger"
      );
    }
  };
  // produk
  const [showBannerForm, setShowBannerForm] = useState(false);
  const [showVideoForm, setShowVideoForm] = useState(false);
  const [showButtonForm, setShowButtonForm] = useState(false);
  useEffect(() => {
    setShowBannerForm(formData.is_image_active === 1);
    setShowVideoForm(formData.is_video_active === 1);
    setShowButtonForm(formData.is_button_active === 1);
  }, [
    formData.is_image_active,
    formData.is_video_active,
    formData.is_button_active,
  ]);

  const renderModalContent = () => {
    switch (activeTab) {
      case "Admin":
        return (
          <>
            <Form.Group className="form-group" controlId="name">
              <Form.Label>Nama</Form.Label>
              <Form.Control
                type="text"
                placeholder="Input Nama"
                value={formData.name}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group className="form-group" controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Input Username (Tanpa Spasi)"
                value={formData.username}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group className="form-group" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Input Password"
                value={formData.password}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group className="form-group" controlId="confirmPassword">
              <Form.Label>Konfirmasi Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Konfirmasi Password"
                value={formData.confirmPassword}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group className="form-group" controlId="phone">
              <Form.Label>No Whatsapp</Form.Label>
              <Form.Control
                type="text"
                placeholder="Cth. +628**********"
                value={formData.phone}
                onChange={handleFormChange}
              />
            </Form.Group>
          </>
        );
      case "Carousel":
        return (
          <>
            <Form.Group className="form-group" controlId="title">
              <Form.Label>Nama</Form.Label>
              <Form.Control
                type="text"
                placeholder="Input nama"
                value={formData.title}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group controlId="image_url">
              <Form.Label>Gambar Carousel</Form.Label>
              <Form.Control
                type="file"
                className="border-0"
                onChange={handleFormChange}
              />
              {formData.image_url && (
                <img src={urlAPI + formData.image_url} alt="" />
              )}
            </Form.Group>
          </>
        );
      case "Metode Pembayaran":
        return (
          <>
            <Form.Group className="form-group" controlId="name">
              <Form.Label>Nama</Form.Label>
              <Form.Control
                type="text"
                placeholder="Input nama"
                value={formData.name}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group controlId="image_url">
              <Form.Label>Gambar Pembayaran</Form.Label>
              <Form.Control
                type="file"
                className="border-0"
                onChange={handleFormChange}
              />
            </Form.Group>
          </>
        );
      case "Metode Pengiriman":
        return (
          <>
            <Form.Group className="form-group" controlId="name">
              <Form.Label>Nama</Form.Label>
              <Form.Control
                type="text"
                placeholder="Input nama"
                value={formData.name}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group controlId="image_url">
              <Form.Label>Gambar Pengiriman</Form.Label>
              <Form.Control
                type="file"
                className="border-0"
                onChange={handleFormChange}
              />
            </Form.Group>
          </>
        );
      case "Menu Bawah":
        return (
          <>
            <Form.Group className="form-group" controlId="title">
              <Form.Label>Nama</Form.Label>
              <Form.Control
                type="text"
                placeholder="Input nama"
                value={formData.title}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group className="form-group" controlId="url">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Input link url"
                value={formData.url}
                onChange={handleFormChange}
              />
            </Form.Group>
          </>
        );
      case "Tampilan Produk":
        return (
          <>
            <Form.Group controlId="name">
              <Form.Label>Nama</Form.Label>
              <Form.Control
                type="text"
                placeholder="Input Nama"
                value={formData.name}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group controlId="category_id">
              <Form.Label>Kategori</Form.Label>
              <Form.Control
                as="select"
                value={formData.category_id}
                onChange={handleFormChange}
              >
                <option value="">Pilih Kategori</option>
                {categories.length > 0 &&
                  categories.map((item) => (
                    <option key={item.category_id} value={item.category_id}>
                      {item.category_name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="pageView">
              <Form.Label>Tampilan</Form.Label>
              <Form.Control
                as="select"
                value={formData.pageView}
                onChange={handleFormChange}
              >
                <option value="">Pilih Tampilan (dalam satu baris)</option>
                <option value="large">5 Produk</option>
                <option value="small">6 Produk</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="is_image_active">
              <Form.Check
                type="checkbox"
                label="Checklist Banner"
                onChange={handleFormChange}
                checked={formData.is_image_active}
              />
            </Form.Group>
            {showBannerForm && (
              <Form.Group controlId="image_url">
                <Form.Label>Gambar Banner</Form.Label>
                <Form.Control
                  type="file"
                  className="border-0"
                  onChange={handleFormChange}
                />
              </Form.Group>
            )}
            <Form.Group controlId="is_video_active">
              <Form.Check
                type="checkbox"
                label="Checklist Video"
                onChange={handleFormChange}
                checked={formData.is_video_active}
              />
            </Form.Group>
            {showVideoForm && (
              <Form.Group controlId="video_url">
                <Form.Label>Video Banner</Form.Label>
                <Form.Control
                  type="file"
                  className="border-0"
                  onChange={handleFormChange}
                />
              </Form.Group>
            )}
            <Form.Group controlId="is_button_active">
              <Form.Check
                type="checkbox"
                label="Checklist Button"
                onChange={handleFormChange}
                checked={formData.is_button_active}
              />
            </Form.Group>
            {showButtonForm && (
              <>
                <Form.Group controlId="button_text">
                  <Form.Label>Button Text</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Button Text"
                    value={formData.button_text}
                    onChange={handleFormChange}
                  />
                </Form.Group>
                <Form.Group controlId="button_link">
                  <Form.Label>Button Link</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Button Link"
                    value={formData.button_link}
                    onChange={handleFormChange}
                  />
                </Form.Group>
              </>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto">
          {editMode ? `Edit ${activeTab}` : `Tambah ${activeTab}`}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleFormSubmit}>
        <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
          {renderModalContent()}
        </Modal.Body>
        <Modal.Footer>
          <Button className="m-auto" variant="primary" type="submit">
            {editMode ? "Simpan Perubahan" : "Tambah"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

// diskon member
export const ModalDiscount = ({
  showModalDiscount,
  handleClose,
  userId,
  currentDiscount,
  updateAgenDiscount,
}) => {
  const [alert, showAlert] = useAlert();
  const [discount, setDiscount] = useState(currentDiscount);

  useEffect(() => {
    setDiscount(currentDiscount);
    updateAgenDiscount(userId, discount);
  }, [currentDiscount]);

  const handleDiscountSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await getDiskonMember(discount, userId);

      // Menampilkan notifikasi sukses
      showAlert("Diskon member ditambahkan", "success");
      updateAgenDiscount(userId, discount);
      handleClose(); // Tutup modal setelah selesai
    } catch (error) {
      console.error("Error updating discount:", error);
      showAlert("Gagal menambahkan diskon member", "danger");
    } finally {
      updateAgenDiscount(userId, discount);
    }
  };
  return (
    <>
      <AlertComponent alert={alert} />
      <Modal show={showModalDiscount} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="ms-auto">Diskon Member</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleDiscountSubmit}>
          <Modal.Body>
            <Form.Group controlId="discount">
              <Form.Label>Diskon (%)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Diskon (%)"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="m-auto" variant="primary" type="submit">
              Set
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

// detail pesanan
export const ModalDetail = ({
  showModalDetail,
  handleClose,
  orderId,
  type,
}) => {
  const [detailPesanan, setDetailPesanan] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const handleReviewRedirect = () => {
    if (
      (detailPesanan.status === "COMPLETED" ||
        detailPesanan.status === "PAID") &&
      detailPesanan.is_reviewed !== "1"
    ) {
      window.location.href = `/review/${detailPesanan.salesorder_id}`;
    }
  };
  const handleReviewByIdRedirect = () => {
    if (
      detailPesanan.status === "COMPLETED" ||
      detailPesanan.status === "PAID"
    ) {
      window.location.href = `/review-list/${detailPesanan.salesorder_id}`;
    }
  };

  useEffect(() => {
    const getDetail = async () => {
      if (orderId) {
        setIsLoading(true);
        let response;

        if (type === "ethica") {
          response = await getEthicaOrder(orderId);
        } else {
          response = await getDetailPesanan(orderId); // Regular order detail fetching
        }

        setDetailPesanan(response);
        setIsLoading(false);
      }
    };
    getDetail();
  }, [orderId, type]);

  const formatCurrency = (value) => {
    if (typeof value === "number") {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(value);
    }
    return "Rp 0";
  };
  const formatQuantity = (qty) => {
    return parseInt(qty, 10); // Mengubah string menjadi integer
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "COMPLETED":
        return "bg-success"; // hijau
      case "PENDING":
        return "bg-primary"; // biru
      default:
        return "bg-secondary"; // warna default jika status tidak dikenal
    }
  };

  // const totalOrderAmount = detailPesanan.reduce(
  //   (acc, item) => acc + Number(item.totalmst),
  //   0
  // );
  const renderOrderDetails = () => {
    if (type === "ethica") {
      return (
        <>
          <div className="container">
            <div className="row">
              <div className="col d-flex flex-column gap-1">
                <h7 className="text-capitalize">nama kustomer</h7>
                <h6 className="text-capitalize">
                  {detailPesanan[0].nama_kirim}
                </h6>
              </div>
              <div className="col d-flex flex-column gap-1">
                <h7 className="text-capitalize">No. Pesanan</h7>
                <h6 className="text-capitalize">{detailPesanan[0].nomor}</h6>
              </div>
            </div>
            <div className="row mt-1">
              <div className="col d-flex flex-column gap-1">
                <h7 className="text-capitalize">waktu pemesanan</h7>
                <h6 className="text-capitalize">{detailPesanan[0].tanggal}</h6>
              </div>
              <div className="col d-flex flex-column gap-1">
                <h7 className="text-capitalize">Status pesanan</h7>
                <h6
                  className={`text-capitalize p-2 w-50 text-center rounded-pill ${getStatusClass(
                    detailPesanan[0].status
                  )}`}
                  style={{ color: "white" }}
                >
                  {detailPesanan[0].status}
                </h6>
              </div>
            </div>

            <hr />
            <div>
              {detailPesanan.map((item) => (
                <div key={item.id} className="row">
                  <div className="col">
                    <img
                      src={item.gambar_besar}
                      alt="gambar"
                      className="w-100 "
                    />
                  </div>
                  <div className="col flex-column d-flex gap-2">
                    <div className="d-flex flex-column justify-content-between gap-1">
                      <h7 className="text-capitalize">nama barang</h7>
                      <h6 className="text-capitalize">{item.nama}</h6>
                    </div>
                    <div className="d-flex justify-content-between gap-1">
                      <h7 className="text-capitalize">jumlah</h7>
                      <h6 className="text-capitalize">
                        x{formatQuantity(item.qty_pesan)}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between gap-1">
                      <h7 className="text-capitalize">harga</h7>
                      <h6 className="text-capitalize">
                        {formatCurrency(Number(item.harga))}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between gap-1">
                      <h7 className="text-capitalize">total</h7>
                      <h6 className="text-capitalize">
                        {formatCurrency(Number(item.harga) * item.qty_pesan)}
                      </h6>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex justify-content-between">
                <h7 className="text-capitalize">biaya pengiriman :</h7>
                <h6>{formatCurrency(Number(detailPesanan[0].ongkos_kirim))}</h6>
              </div>
              <div className="d-flex justify-content-between">
                <h4 className="text-capitalize">total pesanan :</h4>
                <h5>
                  {formatCurrency(
                    Number(detailPesanan[0].subtotalmst) +
                      Number(detailPesanan[0].ongkos_kirim)
                  )}
                </h5>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="container">
            <div className="row">
              <div className="col d-flex flex-column gap-1">
                <h7 className="text-capitalize">nama kustomer</h7>
                <h6 className="text-capitalize">
                  {detailPesanan.customer_name}
                </h6>
              </div>
              <div className="col d-flex flex-column gap-1">
                <h7 className="text-capitalize">No. Pesanan</h7>
                <h6 className="text-capitalize">
                  {detailPesanan.salesorder_no}
                </h6>
              </div>
            </div>
            <div className="row mt-1">
              <div className="col d-flex flex-column gap-1">
                <h7 className="text-capitalize">waktu pemesanan</h7>
                <h6 className="text-capitalize">
                  {new Date(
                    detailPesanan.transaction_date
                  ).toLocaleDateString()}
                </h6>
              </div>
              <div className="col d-flex flex-column gap-1">
                <h7 className="text-capitalize">Status pesanan</h7>
                <h6
                  className={`text-capitalize p-2 w-50 text-center rounded-pill ${getStatusClass(
                    detailPesanan.status
                  )}`}
                  style={{ color: "white" }}
                >
                  {detailPesanan.status}
                </h6>
              </div>
            </div>
            <hr />
            <div>
              {detailPesanan.items?.map((item) => (
                <div key={item.id} className="row">
                  <div className="col">
                    <img src={item.thumbnail} alt="gambar" className="w-100 " />
                  </div>
                  <div className="col flex-column d-flex gap-2">
                    <div className="d-flex flex-column justify-content-between gap-1">
                      <h7 className="text-capitalize">nama barang</h7>
                      <h6 className="text-capitalize">{item.item_name}</h6>
                    </div>
                    <div className="d-flex justify-content-between gap-1">
                      <h7 className="text-capitalize">jumlah</h7>
                      <h6 className="text-capitalize">
                        x{formatQuantity(item.qty)}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between gap-1">
                      <h7 className="text-capitalize">harga</h7>
                      <h6 className="text-capitalize">
                        {formatCurrency(Number(item.price))}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between gap-1">
                      <h7 className="text-capitalize">total</h7>
                      <h6 className="text-capitalize">
                        {formatCurrency(Number(item.amount))}
                      </h6>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex justify-content-between">
                <h7 className="text-capitalize">biaya pengiriman :</h7>
                <h6>{formatCurrency(Number(detailPesanan.shipping_cost))}</h6>
              </div>
              <div className="d-flex justify-content-between">
                <h4 className="text-capitalize">total pesanan :</h4>
                <h5>{formatCurrency(Number(detailPesanan.grand_total))}</h5>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <Modal show={showModalDetail} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto">Detail Pesanan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="text-center pt-50 pb-50">
            <h5>Mohon tunggu, detail produk akan muncul sebentar lagi...</h5>
          </div>
        ) : (
          renderOrderDetails()
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          disabled={
            !(
              (detailPesanan.status === "COMPLETED" ||
                detailPesanan.status === "PAID") &&
              detailPesanan.is_reviewed !== "1"
            )
          }
          className="btn text-capitalize"
          onClick={handleReviewRedirect}
        >
          nilai pesanan
        </button>
        <button
          disabled={detailPesanan.status !== "COMPLETED"}
          className="btn text-capitalize"
          style={{
            backgroundColor: "white",
            color: "#b83bb3",
            border: "1px solid #b83bb3",
            borderRadius: "5px",
          }}
          onClick={handleReviewByIdRedirect}
        >
          lihat ulasan
        </button>
      </Modal.Footer>
    </Modal>
  );
};
