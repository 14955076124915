import React, { useEffect, useState } from "react";
import { postData } from "../../services/api.services";
import { userInfo } from "./AuthProvider";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import AlertComponent, { useAlert } from "../../components/commons/alert";
import Modal from "../../components/commons/modal";

function Register() {
    const [alert, showAlert] = useAlert();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    
    const [register, setRegister] = useState({
        name: '',
        username: '',
        password: '',
        confirm_password: '',
        role_id: '',
        phone: '',
    });

    const handleInput = (event) => {
        const { name, value } = event.target;
        setRegister({
            ...register,
            [name]: value
        });
    };

    const handlePost = async (event) => {
        event.preventDefault();
        if (register.password !== register.confirm_password) {
            showAlert('Password dan konfirmasi password tidak sama', 'danger');
            return;
        }

        const response = await postData('auth/register', register);
        if (response && response.data.success === true) {
            setModalContent(response.data);
            setModalOpen(true);
        } else {
            showAlert('Pendaftaran gagal, isi semua data', 'danger');
        }
    };
    const handleWhatsapp = () => {
        const message = `Halo, saya telah mendaftar sebagai ${modalContent.user.role.name} dengan username ${modalContent.user.username} dan nomor WhatsApp ${modalContent.user.phone}. Mohon ditindaklanjuti statusnya. Terimakasih.`;
        const encodedMessage = encodeURIComponent(message);
        // ganti sesuai landing_page
        window.open(`https://wa.me/?phone=6282210109967&text=${encodedMessage}`, '_blank');
    };


    if (userInfo) {
        return <Redirect to="/account" />;
    }

    return (
        <div id="register">
            <AlertComponent alert={alert} />
            <main className="main pages">
                <div className="page-header breadcrumb-wrap">
                    <div className="container">
                        <div className="breadcrumb">
                            <a href="/" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                            <span></span> Daftar
                        </div>
                    </div>
                </div>
                <div className="page-content pt-50 pb-150">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                                <div className="row">
                                    <div className="col-lg-6 col-md-8 m-auto text-center">
                                        <div className="login_wrap widget-taber-content background-white">
                                            <div className="padding_eight_all bg-white">
                                                <div className="heading_s1">
                                                    <h2 className="mb-5">Daftar</h2>
                                                    <p>Silahkan isi form di bawah ini</p>
                                                    <p>Sudah punya akun? <a href="/login">Masuk</a></p>
                                                    <p className="mb-30"></p>
                                                </div>
                                                <form onSubmit={handlePost}>
                                                    <div className="text-lg-start">
                                                        <div className="form-group">
                                                            <div className="custom_select">
                                                                <select className="form-control select-active" name="role_id" onChange={handleInput} value={register.role_id} required>
                                                                    <option value="">Pilih Sebagai *</option>
                                                                    <option value="2">Agen</option>
                                                                    <option value="3">Reseller</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Nama <span className="required">*</span></label>
                                                            <input type="text" required onChange={handleInput} value={register.name} id="name" name="name" placeholder="Isi Nama Anda" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Nomor Whatsapp <span className="required">*</span></label>
                                                            <input type="text" required onChange={handleInput} value={register.phone} id="phone" name="phone" placeholder="Cth. +628**********" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Username <span className="required">*</span></label>
                                                            <input type="text" required onChange={handleInput} value={register.username} id="username" name="username" placeholder="Isi Username Anda (Tanpa Spasi)" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Password <span className="required">*</span></label>
                                                            <input required type="password" onChange={handleInput} value={register.password} id="password" name="password" placeholder="Isi Password Anda" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Konfirmasi Password <span className="required">*</span></label>
                                                            <input required type="password" onChange={handleInput} value={register.confirm_password} id="confirm_password" name="confirm_password" placeholder="Konfirmasi Password Anda" />
                                                        </div>
                                                    </div>
                                                    <div className="mb-30"></div>
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-heading btn-block hover-up" name="register">Daftar</button>
                                                    </div>
                                                </form>
                                                {modalContent &&
                                                <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                                                    <h3>Pendaftaran Berhasil</h3>
                                                    <p>Data Pendaftaran:</p>
                                                    <div className="text-start mb-4 w-50 m-auto">
                                                        <p>Nama : {modalContent.user.name}</p>
                                                        <p>Username : {modalContent.user.username}</p>
                                                        <p>No. Whatsapp : {modalContent.user.phone}</p>
                                                        <p>Daftar Sebagai : {modalContent.user.role.name}</p>
                                                        <p>Status : <span className={`${modalContent.user.approved == true ? 'badge bg-success':'badge bg-danger'}`}>{modalContent.user.approved == true ? 'Aktif' : 'Belum Aktif'}</span></p>
                                                    </div>
                                                    <a onClick={handleWhatsapp} className="btn btn-small m-auto">Hubungi Kami</a>
                                                </Modal>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Register;