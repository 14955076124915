import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getBlogById, urlAPI } from "../../services/api.services";
import { useEffect, useState } from "react";

export const DetailArtikel = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBlogById(id);
        setBlog(response);
      } catch (err) {}
    };

    fetchData();
  }, [id]);

  const description = blog?.description || "";
  const cleanDescription = description.replace(/<\/?p>/g, "");
  return (
    <div>
      <main class="main pages">
        <div class="page-header breadcrumb-wrap">
          <div class="container">
            <div class="breadcrumb">
              <a href="/" rel="nofollow">
                <i class="fi-rs-home mr-5"></i>Beranda
              </a>
              <span></span>
              <a href="/artikel" rel="nofollow">
                artikel
              </a>
              <span></span> Detail Artikel
            </div>
          </div>
        </div>
        <div class="page-content pr-50 pl-50 pt-20 pt-md-5 pb-150">
          <div class="container">
            <div className="d-flex gap-2 pb-2 flex-column align-items-center justify-content-center">
              <h3 className="text-capitalize">{blog?.title}</h3>
              <p className="">
                {" "}
                {new Date(blog?.created_at).toLocaleDateString()}
              </p>
            </div>

            <div className="d-flex gap-4 flex-column">
              <div className="d-flex justify-content-center align-items-center pt-5">
                <img
                  src={urlAPI + blog?.image}
                  alt={blog?.title}
                  className="w-25 h-25"
                />
              </div>
              <div className="d-flex flex-column gap-2 container-xl px-3 px-md-4 px-lg-5 px-xl-6">
                <h5 className="text-capitalize">{blog?.title}</h5>
                <p style={{ textAlign: "justify" }}>{cleanDescription}</p>
              </div>
              {/* <div>
                <h5 className="text-capitalize">Lorem ipsum dolor sit amet.</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Obcaecati optio vitae ad voluptas ipsa voluptate quasi
                  accusamus ab in temporibus, expedita officiis, explicabo et
                  inventore aliquam hic voluptatem quibusdam eum magni nostrum
                  sunt dolorum perspiciatis. Iure numquam similique deleniti,
                  laboriosam modi quidem at magnam consectetur illo voluptatibus
                  doloremque ad cumque est tempora, sit, eius dicta labore!
                  Corrupti aperiam ad numquam, consequuntur dignissimos illo.
                  Necessitatibus nobis suscipit officia laudantium, adipisci
                  nihil tempora exercitationem, quos explicabo itaque libero
                  voluptates provident veritatis iste illum laboriosam omnis
                  nisi fugit iure. Adipisci molestiae recusandae, veritatis
                  iusto consectetur deserunt, exercitationem temporibus error
                  quas, fugiat odit sit?
                </p>
              </div>
              <div>
                <h5 className="text-capitalize">
                  Lorem, ipsum dolor sit amet consectetur adipisicing.
                </h5>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Mollitia ducimus veniam deserunt consequuntur explicabo fugiat
                  veritatis aliquam iusto eligendi earum.
                </p>
              </div>
              <div>
                <h5 className="text-capitalize">Lorem ipsum dolor sit amet.</h5>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
                  natus vero perspiciatis veritatis unde! Qui autem pariatur
                  maiores corporis provident!
                </p>
              </div>

              <p className="pt-50">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Distinctio, explicabo!
              </p> */}
            </div>
          </div>
        </div>
      </main>{" "}
    </div>
  );
};
