import React from "react";
import Footer from "./Footer";
import FloatingActionButtons from "../commons/buttons/BtnFloat";
import NavbarPro from "./Navbar/NavbarPro";

const Index = ({ children }) => {
  return (
    <div className="w-full h-full">
      <NavbarPro />
      <main>{children}</main>
      <FloatingActionButtons/>
      <Footer />
    </div>
  );
};

export default Index;
