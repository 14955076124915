import { FaCartShopping } from "react-icons/fa6";
import { IoPerson } from "react-icons/io5";
import { useEffect, useState } from "react";
import { Logo } from "../../../assets/images";
import { userInfo } from "../../../pages/Account/AuthProvider";

function NavbarLP() {
  const [isScrolled, setIsScrolled] = useState(false);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  useEffect(() => {
    const changeColor = () => {
      if (window.scrollY >= 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", changeColor);

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  return (
    <>
      {["md"].map((expand) => (
        <>
          <nav
            class={`navbar navbar-expand-lg fixed-top navbar-light ${
              isScrolled || showOffcanvas ? "bg-white" : ""
            }`}
            aria-label="Main navigation"
          >
            <div class="container-fluid">
              <a class="navbar-brand" href="/">
                <img src={Logo} alt="" style={{ width: "50%" }} />
              </a>
              <button
                class="navbar-toggler p-0 border-0"
                type="button"
                id="navbarSideCollapse"
                aria-label="Toggle navigation"
                onClick={toggleOffcanvas}
              >
                <span class="navbar-toggler-icon"></span>
              </button>

              <div
                class={`navbar-collapse offcanvas-collapse ${
                  showOffcanvas ? " open" : ""
                }`}
                id="navbarsExampleDefault"
              >
                <ul class="d-flex navbar-nav ms-auto mb-2 mb-lg-0 fs-6">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      aria-current="page"
                      href="/product"
                    >
                      Katalog Produk
                    </a>
                  </li>
                  {!userInfo && (
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        aria-current="page"
                        href="/register"
                      >
                        Daftar Mitra
                      </a>
                    </li>
                  )}
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      aria-current="page"
                      href="/artikel"
                    >
                      Artikel
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      aria-current="page"
                      href="/tentang-kami"
                    >
                      Tentang kami
                    </a>
                  </li>
                  <div className="d-flex">
                    <li class="nav-item">
                      <a class="nav-link" href="/cart">
                        <FaCartShopping
                          style={{ width: "1.25rem", height: "1.25rem" }}
                        />
                        <span class="ms-2 me-4 d-lg-none">Keranjang</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      {userInfo ? (
                        <a class="nav-link" href="/account">
                          <IoPerson
                            style={{ width: "1.25rem", height: "1.25rem" }}
                          />
                          <span class="ms-2 d-lg-none">Akun</span>
                        </a>
                      ) : (
                        <a class="nav-link" href="/login">
                          Masuk
                        </a>
                      )}
                    </li>
                  </div>
                </ul>
                {/* <form class="d-flex">
                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
                <button class="btn btn-outline-success" type="submit">Search</button>
              </form> */}
              </div>
            </div>
          </nav>
        </>
      ))}
    </>
  );
}

export default NavbarLP;
