import { useEffect, useRef, useState } from "react";
import {
  Logo,
  Product,
  Product2,
  iconCart,
  iconHeart,
  iconUser,
} from "../../../assets/images";
import {
  apiFunctions,
  deleteCart,
  fetchData,
  getEthicaProductFromCart,
  getProductsEthica,
} from "../../../services/api.services";
import {
  formatPrice,
  getCookie,
  setCategoryCookie,
} from "../../../services/function";
import {
  getCartEthicaTotalQuantity,
  getCartItems,
  getCartItemsEthica,
  getCartSubtotal,
  getCartSubtotalEthica,
  getCartTotalQuantity,
  updateCartItemQuantity,
} from "../../../pages/Cart/Cart";
import { userInfo } from "../../../pages/Account/AuthProvider";
import { useSearch } from "../../../pages/Search/SearchContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import AlertComponent, { useAlert } from "../../commons/alert";
import { SettingsCellRounded } from "@mui/icons-material";

function NavbarPro() {
  const time = new Date();
  const history = useHistory();
  const [alert, showAlert] = useAlert();
  const { query, setQuery, results, setResults, loading, setLoading } =
    useSearch();
  const [showResults, setShowResults] = useState(false);
  const resultsContainerRef = useRef(null);

  const [isScrolled, setIsScrolled] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [product, setProduct] = useState([]);
  const [categoryActive, setCategoryActive] = useState([]);
  const [ethicaProduct, setEthicaProduct] = useState([]);
  const [footerSet, setFooterSet] = useState(null);

  const [cartItems, setCartItems] = useState([]);
  const [cartItemsEthica, setCartItemsEthica] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [totalEthica, setTotalEthica] = useState(0);
  const [count, setCount] = useState(null);
  const [countEthica, setCountEthica] = useState(null);

  const fetchCartData = () => {
    setCartItems(getCartItems());
    setSubtotal(getCartSubtotal());
    setCount(getCartTotalQuantity());
  };

  const combinedCount = count + countEthica;
  const combinedSubtotal = subtotal + totalEthica;

  const calculateTotalEthica = (items) => {
    if (!Array.isArray(items)) {
      console.error("Items is not an array:", items);
      return 0;
    }
    return items.reduce(
      (total, item) =>
        total + parseFloat(item.harga) * parseInt(item.qty_order),
      0
    );
  };

  const calculateQuantityEthica = (items) => {
    if (!Array.isArray(items)) {
      console.error("Items is not an array:", items);
      return 0;
    }
    return items.reduce((total, item) => total + parseInt(item.qty_order), 0);
  };

  const fetchCartDataEthica = async () => {
    try {
      const response = await getEthicaProductFromCart();
      setCartItemsEthica(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCartData();
    fetchCartDataEthica();

    const handleCartUpdated = () => {
      fetchCartData();
      fetchCartDataEthica();
    };
    window.addEventListener("cartUpdated", handleCartUpdated);

    return () => {
      window.removeEventListener("cartUpdated", handleCartUpdated);
    };
  }, []);

  useEffect(() => {
    const ethicaTotal = calculateTotalEthica(cartItemsEthica);
    setCountEthica(calculateQuantityEthica(cartItemsEthica));
    setTotalEthica(ethicaTotal);
  }, [cartItemsEthica]);

  const handleQuantityChange = (itemId, increment) => {
    const updatedCart = cartItems
      .map((item) => {
        if (item.item_id === itemId) {
          const newQuantity = item.quantity + increment;
          if (newQuantity > 0 && newQuantity <= item.end_qty) {
            updateCartItemQuantity(itemId, newQuantity);
            return { ...item, quantity: newQuantity };
          } else if (newQuantity <= 0) {
            updateCartItemQuantity(itemId, newQuantity);
            return null; // Remove item from state
          } else {
            showAlert("Anda telah mencapai stok maksimum.", "danger");
          }
        }
        return item;
      })
      .filter((item) => item !== null);

    setCartItems(updatedCart);
    setSubtotal(getCartSubtotal());
    setCount(updatedCart.reduce((total, item) => total + item.quantity, 0));
  };

  const handleDeleteItem = (itemId) => {
    const updatedCart = cartItems.filter((item) => item.item_id !== itemId);
    localStorage.setItem("cart", JSON.stringify(updatedCart));

    setCartItems(updatedCart);
    setSubtotal(
      updatedCart.reduce(
        (total, item) => total + item.quantity * item.sell_price,
        0
      )
    );
    setCount(updatedCart.reduce((total, item) => total + item.quantity, 0));
  };

  const handleDeleteItemEthica = async (seq) => {
    const deleteItem = await deleteCart(seq);

    if (deleteItem) {
      const updatedCartItemsEthica = cartItemsEthica.filter(
        (item) => item.seq !== seq
      );
      setCartItemsEthica(updatedCartItemsEthica);
      localStorage.setItem(
        "cart-ethica",
        JSON.stringify(updatedCartItemsEthica)
      );
      setTotalEthica(
        updatedCartItemsEthica.reduce(
          (total, item) =>
            total + parseInt(item.qty_order) * parseFloat(item.harga),
          0
        )
      );
      setCountEthica(
        updatedCartItemsEthica.reduce(
          (total, item) => total + parseInt(item.qty_order),
          0
        )
      );
    }
  };

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  useEffect(() => {
    const changeColor = () => {
      if (window.scrollY >= 150) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", changeColor);
    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  const Logout = () => {
    localStorage.removeItem("user-info");
    window.location.href = "/login";
  };

  useEffect(() => {
    apiFunctions.products((data) => {
      const sortedData = data.data.sort(
        (a, b) => new Date(b.last_modified) - new Date(a.last_modified)
      );
      setProduct(sortedData);
    });
    fetchData("jubelio/categories/active", "", (data) => {
      setCategoryActive(data);
    });
    const fetchProductEthica = async () => {
      const data = await getProductsEthica();
      setEthicaProduct(data);
    };
    fetchProductEthica();
    fetchData("footer/menu", "", (data) => {
      setFooterSet(data.data.settings);
    });
  }, []);

  // mobile menu
  useEffect(() => {
    const mobileMenu = document.querySelector(".mobile-menu");
    const subMenus = mobileMenu.querySelectorAll(".dropdown");
    subMenus.forEach((subMenu) => {
      const parentLi = subMenu.parentElement;
      const expandButton = document.createElement("span");
      expandButton.classList.add("menu-expand");
      expandButton.innerHTML = '<i class="fi-rs-angle-small-down"></i>';
      parentLi.prepend(expandButton);
    });
    const handleMenuClick = (e) => {
      const target = e.target;
      const li = target.closest("li");
      const isMenuExpand =
        target.classList.contains("menu-expand") ||
        target.closest(".menu-expand");

      if (
        li &&
        (li.classList.contains("menu-item-has-children") ||
          li.classList.contains("has-children") ||
          li.classList.contains("has-sub-menu"))
      ) {
        const subMenu = li.querySelector(".dropdown");
        if (
          subMenu &&
          ((target.tagName === "A" && target.getAttribute("href") === "#") ||
            isMenuExpand)
        ) {
          e.preventDefault();
          const isVisible = subMenu.style.display === "block";
          closeAllSubMenus();
          if (!isVisible) {
            li.classList.add("active");
            subMenu.style.display = "block";
          }
        }
      }
    };
    const closeAllSubMenus = () => {
      subMenus.forEach((subMenu) => {
        subMenu.style.display = "none";
        subMenu.parentElement.classList.remove("active");
      });
    };
    mobileMenu.addEventListener("click", handleMenuClick);
    closeAllSubMenus();
    return () => {
      mobileMenu.removeEventListener("click", handleMenuClick);
    };
  }, [categoryActive, product]);
  //   search
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        resultsContainerRef.current &&
        !resultsContainerRef.current.contains(event.target)
      ) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSearch = (event) => {
    setQuery(event.target.value);
    setShowResults(true);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    history.push("/search-results");
  };

  const fetchProducts = (response) => {
    if (response && response.success && response.data) {
      const productsArray = Object.keys(response.data).map((key) => ({
        id: key,
        ...response.data[key],
      }));
      setResults(productsArray);
    } else {
      setResults([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (query.length > 0) {
      setLoading(true);
      apiFunctions.productSearch(query, fetchProducts);
    } else {
      setResults([]);
    }
  }, [query]);

  return (
    <>
      <header class="header-area header-style-1 header-height-2">
        <AlertComponent alert={alert} />
        <div class="header-middle header-middle-ptb-1 d-none d-lg-block">
          <div class="container">
            <div class="header-wrap">
              <div class="logo logo-width-1">
                <a href="/">
                  <img src={Logo} alt="logo" />
                </a>
              </div>
              <div class="header-right">
                <div class="search-style-2">
                  <form onSubmit={handleSubmit} ref={resultsContainerRef}>
                    <input
                      type="text"
                      placeholder="Cari Produk..."
                      value={query}
                      onChange={handleSearch}
                      onClick={() => setShowResults(true)}
                    />
                    {loading && (
                      <p className="position-absolute top-50 end-0 translate-middle">
                        loading...
                      </p>
                    )}
                  </form>
                  {showResults && !loading && results && results.length > 0 ? (
                    <div
                      className="results-container"
                      style={{ maxHeight: "200px", overflowY: "auto" }}
                    >
                      {results.map((product, index) => (
                        <div
                          key={index}
                          className="result-item p-1 border-bottom"
                        >
                          <a href={`/product/${product.item_group_id}`}>
                            <p>{product.item_name}</p>
                          </a>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div class="header-action-right">
                  <div class="header-action-2">
                    <div class="header-action-icon-2">
                      <a class="mini-cart-icon" href="/cart">
                        <img alt="Nest" src={iconCart} />
                        <span class="pro-count blue">{combinedCount}</span>
                      </a>
                      <a href="/cart">
                        <span class="lable">Keranjang</span>
                      </a>
                      <div class="cart-dropdown-wrap cart-dropdown-hm2">
                        <ul>
                          {cartItems.map((item) => (
                            <li key={item.item_id}>
                              <div class="shopping-cart-img">
                                <a href={`/product/${item.item_group}`}>
                                  <img
                                    alt={item.item_code}
                                    src={
                                      item.images.length > 0
                                        ? item.images[0].cloud_key
                                        : ""
                                    }
                                  />
                                </a>
                              </div>
                              <div class="shopping-cart-title">
                                <h4>
                                  <a href={`/product/${item.item_group}`}>
                                    {item.item_name}
                                  </a>
                                </h4>
                                <div className="d-flex">
                                  <div className="d-flex">
                                    <a
                                      className="qty-down"
                                      onClick={() =>
                                        handleQuantityChange(item.item_id, -1)
                                      }
                                    >
                                      <i className="fi-rs-minus-small"></i>
                                    </a>
                                    <h4 className="m-1">
                                      <span>{item.quantity}</span>
                                    </h4>
                                    <a
                                      className="qty-up"
                                      onClick={() =>
                                        handleQuantityChange(item.item_id, 1)
                                      }
                                    >
                                      <i className="fi-rs-plus-small"></i>
                                    </a>
                                  </div>
                                  <h4 className="m-1 ms-2">
                                    × {formatPrice(item.sell_price)}
                                  </h4>
                                </div>
                              </div>
                              <div class="shopping-cart-delete">
                                <a
                                  onClick={() => handleDeleteItem(item.item_id)}
                                >
                                  <i class="fi-rs-cross-small"></i>
                                </a>
                              </div>
                            </li>
                          ))}

                          {/* data etchica */}
                          {Array.isArray(cartItemsEthica) &&
                          cartItemsEthica.length > 0 ? (
                            <>
                              {cartItemsEthica.map((item) => (
                                <li key={item.seq}>
                                  <div class="shopping-cart-img">
                                    <a href={`/product-ethica/${item.nama}`}>
                                      <img
                                        alt={item.gambar}
                                        src={item.gambar}
                                      />
                                    </a>
                                  </div>
                                  <div class="shopping-cart-title">
                                    <h4>
                                      <a href={`/product-ethica/${item.nama}`}>
                                        {item.nama}
                                      </a>
                                    </h4>
                                    <div className="d-flex">
                                      <div className="d-flex">
                                        <h4 className="m-1">
                                          <span>{item.qty_order}</span>
                                        </h4>
                                      </div>
                                      <h4 className="m-1 ms-2">
                                        × {formatPrice(parseFloat(item.harga))}
                                      </h4>
                                    </div>
                                  </div>
                                  <div class="shopping-cart-delete">
                                    <a
                                      onClick={() =>
                                        handleDeleteItemEthica(item.seq)
                                      }
                                    >
                                      <i class="fi-rs-cross-small"></i>
                                    </a>
                                  </div>
                                </li>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </ul>
                        <div class="shopping-cart-footer">
                          <div class="shopping-cart-total">
                            <h4>
                              Total <span>{formatPrice(combinedSubtotal)}</span>
                            </h4>
                          </div>
                          <div class="shopping-cart-button">
                            <a href="/cart" class="w-100 text-center ">
                              Checkout
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="header-action-icon-2">
                      <a href={`${userInfo ? "/account" : "/login"}`}>
                        <img class="svgInject" alt="Nest" src={iconUser} />
                      </a>
                      <a href={`${userInfo ? "/account" : "/login"}`}>
                        <span class="lable ml-0">
                          {userInfo ? userInfo.user.name : "Masuk"}
                        </span>
                      </a>
                      <div
                        class={`cart-dropdown-wrap cart-dropdown-hm2 account-dropdown ${
                          userInfo ? "" : "hidden"
                        }`}
                      >
                        <ul>
                          <li>
                            <a href="/account">
                              <i class="fi fi-rs-user mr-10"></i>Akun Saya
                            </a>
                          </li>
                          <li>
                            <a onClick={Logout}>
                              <i class="fi fi-rs-sign-out mr-10"></i>Keluar
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class={`header-bottom header-bottom-bg-color sticky-bar ${
            isScrolled ? "stick" : ""
          }`}
        >
          <div class="container">
            <div class="header-wrap header-space-between position-relative">
              <div class="logo logo-width-1 d-block d-lg-none">
                <a href="/">
                  <img src={Logo} alt="logo" />
                </a>
              </div>
              <div class="header-nav d-none d-lg-flex">
                <div class="main-categori-wrap d-none d-lg-block">
                  <a
                    class="categories-button-active"
                    onClick={() => setCategoryCookie(null)}
                  >
                    Semua Produk
                  </a>
                </div>
                <div class="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                  <nav>
                    <ul>
                      {/* Category Map */}
                      {categoryActive.length > 0 &&
                        categoryActive.map((item) => {
                          const proSection = product
                            .filter(
                              (pro) => pro.item_category_id === item.category_id
                            )
                            .sort(
                              (a, b) =>
                                new Date(b.last_modified) -
                                new Date(a.last_modified)
                            );
                          return (
                            <li>
                              <a
                                class={`${
                                  parseInt(getCookie("category_id")) ==
                                  item.category_id
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  setCategoryCookie(item.category_id)
                                }
                              >
                                {item.category_name}
                                <i class="fi-rs-angle-down"></i>
                              </a>
                              <ul class="sub-menu">
                                {proSection.map((product) => (
                                  <li>
                                    <a
                                      href={`/product/${product.item_group_id}`}
                                    >
                                      {product.item_name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          );
                        })}

                      {/* Ethica Map */}
                      {userInfo ? (
                        <li>
                          <a href="/product-ethica">
                            Ethica <i className="fi-rs-angle-down"></i>
                          </a>
                          <ul className="sub-menu">
                            {ethicaProduct
                              .sort((a, b) => a.tahun - b.tahun)
                              .map((product) => (
                                <li key={product.artikel}>
                                  <a
                                    href={`/product-ethica/${product.artikel}`}
                                  >
                                    {product.artikel}
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </li>
                      ) : null}
                    </ul>
                  </nav>
                </div>
              </div>
              <div
                class="header-action-icon-2 d-block d-lg-none"
                onClick={toggleOffcanvas}
              >
                <div class="burger-icon burger-icon-white">
                  <span class="burger-icon-top"></span>
                  <span class="burger-icon-mid"></span>
                  <span class="burger-icon-bottom"></span>
                </div>
              </div>
              <div class="header-action-right d-block d-lg-none">
                <div class="header-action-2">
                  <div class="header-action-icon-2">
                    <a class="mini-cart-icon" href="#">
                      <img alt="Nest" src={iconCart} />
                      <span class="pro-count white">{combinedCount}</span>
                    </a>
                    <div class="cart-dropdown-wrap cart-dropdown-hm2">
                      <ul>
                        {cartItems.map((item) => (
                          <li>
                            <div class="shopping-cart-img">
                              <a href={`/product/${item.item_group}`}>
                                <img
                                  alt={item.item_code}
                                  src={
                                    item.images.length > 0
                                      ? item.images[0].cloud_key
                                      : ""
                                  }
                                />
                              </a>
                            </div>
                            <div class="shopping-cart-title">
                              <h4>
                                <a href={`/product/${item.item_group}`}>
                                  {item.item_name}
                                </a>
                              </h4>
                              <h3>
                                <span>{item.quantity} × </span>
                                {formatPrice(item.sell_price)}
                              </h3>
                            </div>
                            <div class="shopping-cart-delete">
                              <a onClick={() => handleDeleteItem(item.item_id)}>
                                <i class="fi-rs-cross-small"></i>
                              </a>
                            </div>
                          </li>
                        ))}

                        {Array.isArray(cartItemsEthica) &&
                        cartItemsEthica.length > 0 ? (
                          <>
                            {cartItemsEthica.map((item) => (
                              <li key={item.artikel}>
                                <div class="shopping-cart-img">
                                  <a href={`/product-ethica/${item.artikel}`}>
                                    <img
                                      alt={item.gambar}
                                      src={item.gambar?.normal}
                                    />
                                  </a>
                                </div>
                                <div class="shopping-cart-title">
                                  <h4>
                                    <a href={`/product-ethica/${item.artikel}`}>
                                      {item.artikel}
                                    </a>
                                  </h4>
                                  <div className="d-flex">
                                    <div className="d-flex">
                                      <h4 className="m-1">
                                        <span>{item.quantity}</span>
                                      </h4>
                                    </div>
                                    <h4 className="m-1 ms-2">
                                      × {formatPrice(item.harga)}
                                    </h4>
                                  </div>
                                </div>
                                <div class="shopping-cart-delete">
                                  <a
                                    onClick={() =>
                                      handleDeleteItemEthica(item.seq)
                                    }
                                  >
                                    <i class="fi-rs-cross-small"></i>
                                  </a>
                                </div>
                              </li>
                            ))}
                          </>
                        ) : (
                          ""
                        )}
                      </ul>

                      <div class="shopping-cart-footer">
                        <div class="shopping-cart-total">
                          <h4>
                            Total <span>{formatPrice(combinedSubtotal)}</span>
                          </h4>
                        </div>
                        <div class="shopping-cart-button justify-content-end">
                          <a href="/cart">Checkout</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="header-action-icon-2">
                    <a href={userInfo ? "/account" : "/login"}>
                      <img alt="Nest" src={iconUser} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div
        class={`mobile-header-active mobile-header-wrapper-style ${
          showOffcanvas ? "sidebar-visible" : ""
        }`}
      >
        <div class="mobile-header-wrapper-inner">
          <div class="mobile-header-top">
            <div class="mobile-header-logo">
              <a href="/">
                <img src={Logo} alt="logo" />
              </a>
            </div>
            <div
              class="mobile-menu-close close-style-wrap close-style-position-inherit"
              onClick={toggleOffcanvas}
            >
              <button class="close-style search-close">
                <i class="icon-top"></i>
                <i class="icon-bottom"></i>
              </button>
            </div>
          </div>
          <div class="mobile-header-content-area">
            <div class="mobile-search search-style-3 mobile-header-border">
              <form action="#">
                <input type="text" placeholder="Cari Produk…" />
                <button type="submit">
                  <i class="fi-rs-search"></i>
                </button>
              </form>
            </div>
            <div class="mobile-menu-wrap mobile-header-border">
              {/* <!-- mobile menu start --> */}
              <nav>
                <ul class="mobile-menu font-heading">
                  {categoryActive.length > 0 &&
                    categoryActive.map((item) => {
                      const proSection = product
                        .filter(
                          (pro) => pro.item_category_id === item.category_id
                        )
                        .sort(
                          (a, b) =>
                            new Date(b.last_modified) -
                            new Date(a.last_modified)
                        );
                      return (
                        <li class="menu-item-has-children">
                          <a
                            onClick={() => setCategoryCookie(item.category_id)}
                          >
                            {item.category_name}
                          </a>

                          <ul className="dropdown">
                            {proSection.map((product) => (
                              <li key={product.item_group_id}>
                                <a href={`/product/${product.item_group_id}`}>
                                  {product.item_name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      );
                    })}
                  {userInfo ? (
                    <li class="menu-item-has-children">
                      <a href="/product-ethica">Ethica</a>
                      <ul className="dropdown">
                        {ethicaProduct
                          .sort((a, b) => a.tahun - b.tahun)
                          .map((product) => (
                            <li key={product.artikel}>
                              <a href={`/product-ethica/${product.artikel}`}>
                                {product.artikel}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </li>
                  ) : null}
                </ul>
              </nav>
              {/* <!-- mobile menu end --> */}
            </div>
            <div class="mobile-header-info-wrap">
              <div class="single-mobile-header-info">
                <a href={userInfo ? "/account" : "/login"}>
                  <i class="fi-rs-user"></i>
                  {userInfo ? "Akun Saya" : "Masuk"}{" "}
                </a>
              </div>
              {footerSet && (
                <div class="single-mobile-header-info">
                  <a
                    href={`https://api.whatsapp.com/send/?phone=${parseInt(
                      footerSet.whatsapp
                    )}`}
                    target="blank"
                  >
                    <i class="fi-rs-headphones"></i>
                    {footerSet.whatsapp}
                  </a>
                </div>
              )}
            </div>
            <div class="site-copyright">
              Copyright {time.getFullYear()} ©{" "}
              {footerSet && footerSet.store_name}.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavbarPro;
